import ReactPaginate from "react-paginate";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useSearchParams } from "react-router-dom";

export default function ProductPagination({ pageCount }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("p") || 1;
  const isMobile = useMediaQuery("(max-width: 768px)");

  const onPageChange = (e) => {
    window.scrollTo(0, 0);
    searchParams.set("p", e.selected + 1);
    setSearchParams(searchParams);
  };

  return (
    <div className="row mt-8">
      <ReactPaginate
        breakLabel="..."
        nextLabel={<i className="feather-icon icon-chevron-right" />}
        onPageChange={onPageChange}
        pageRangeDisplayed={3}
        marginPagesDisplayed={isMobile ? 0 : 1}
        pageCount={pageCount}
        previousLabel={<i className="feather-icon icon-chevron-left" />}
        renderOnZeroPageCount={null}
        pageClassName="page-item d-wrap"
        containerClassName="pagination"
        pageLinkClassName="page-link mx-1"
        nextLinkClassName="page-link mx-1 rounded"
        previousLinkClassName="page-link mx-1 rounded"
        activeClassName="active"
        disabledClassName="disabled"
        initialPage={page - 1}
      />
    </div>
  );
}
