import { useLocation } from "react-router-dom";
import MobileCategoryScreen from "./category";
import useAuthentication from "../../hooks/useAuthentication";
import MobileCartScreen from "./cart";

export default function BottomNav() {
  const location = useLocation();
  const { isLoggedIn } = useAuthentication();

  return (
    <div className="bg-white position-fixed bottom-0 w-100 z-3 shadow-lg d-block d-lg-none text-center">
      <div className="d-flex align-items-center">
        <div className="w-25 py-2 icon-hover">
          <a
            href="/"
            className="text-inherit"
            style={{ color: location.pathname == "/" && "#fe4f4f" }}
            aria-expanded="false"
          >
            <div className="text-center">
              <i class="bi bi-house fs-4"></i>
              <p class="mb-0 small">Нүүр</p>
            </div>
          </a>
        </div>
        <div className="w-25 py-2 icon-hover">
          <a
            class="text-inherit"
            data-bs-toggle="offcanvas"
            href="#offcanvasCategoryMobile"
            role="button"
            aria-controls="offcanvasCategoryMobile"
            style={{
              color: location.pathname.includes("category") && "#fe4f4f",
            }}
          >
            <i class="bi bi-list fs-4"></i>
            <p class="mb-0 small">Ангилал</p>
          </a>
        </div>
        <div className="w-25 py-2 icon-hover">
          <a
            class="text-inherit"
            href="#offcanvasCartMobile"
            role="button"
            aria-controls="offcanvasCartMobile"
            className="text-inherit"
            data-bs-toggle={isLoggedIn ? "offcanvas" : "modal"}
            data-bs-target={
              isLoggedIn ? "#offcanvasCartMobile" : "#signInModal"
            }
          >
            <i class="bi bi-cart fs-4"></i>
            <p class="mb-0 small">Сагс</p>
          </a>
        </div>
        <div className="w-25 py-2 icon-hover">
          <a
            href="/profile/info"
            className="text-inherit"
            style={{
              color: location.pathname.includes("profile") && "#fe4f4f",
            }}
            data-bs-toggle={isLoggedIn ? "" : "modal"}
            data-bs-target={isLoggedIn ? "" : "#signInModal"}
          >
            <i className="bi bi-person fs-4" />
            <p class="mb-0 small">Профайл</p>
          </a>
        </div>
      </div>

      {/* filter */}
      {/* <Category /> */}
      <MobileCartScreen />
      <MobileCategoryScreen />
      {/* <SignIn /> */}
    </div>
  );
}
