import { useParams, useSearchParams } from "react-router-dom";
import { getVendorProducts } from "../../api";
import BrandHeader from "./header";
import { useQuery } from "@tanstack/react-query";
import Products from "../../components/products";

export default function BrandScreen() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("p") || 1;
  const sort = searchParams.get("sort") || "";

  const { isLoading, isError, data } = useQuery({
    queryFn: () => getVendorProducts(id, page, sort),
    queryKey: ["getVendorProducts", id, page, sort],
  });

  return (
    <main>
      <div className="mt-8 mb-14">
        <div className="container">
          <div className="row">
            <section className="col">
              <BrandHeader />
              <Products
                data={data?.Items?.Content}
                loading={isLoading}
                showFilter={true}
                showPagination={true}
                cols={5}
                pageCount={data?.MaximumPageCount}
                totalCount={data?.Items.TotalCount}
              />
            </section>
          </div>
        </div>
      </div>
    </main>
  );
}
