import { useState } from "react";
import { toast } from "react-toastify";
import { formatCurrency } from "../../utils/helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { account } from "../../mock/account";

export default function PaymentModal({ element }) {
  const [isQPay, setIsQpay] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [orderNumber, setOrderNumber] = useState("");

  return (
    <>
      <div
        className="modal fade"
        id="paymentModal"
        tabIndex={-1}
        aria-labelledby="paymentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-4">
            <div className="modal-header border-0">
              <h5 className="modal-title fs-3 fw-bold" id="paymentModalLabel">
                Төлбөр төлөх
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                <span>Захиалгын дугаар: </span>
                <span className="text-black fw-bold">{orderNumber}</span>
              </div>
              <div className="my-3 mb-6">
                <span>Төлөх дүн: </span>
                <span className="text-primary fw-bold">
                  {formatCurrency(totalAmount)}
                </span>
              </div>
              <div className="row g-6 mb-xl-6">
                <div className="col-md-6 col-12">
                  <div className="mb-3 mb-lg-0">
                    <button
                      type="button"
                      className={`btn ${isQPay ? "btn-primary" : "border"}`}
                      style={{ width: "100%" }}
                      onClick={() => setIsQpay(true)}
                    >
                      <i className={`bi bi-check-circle-fill me-3`} />
                      QPAY
                    </button>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="mb-3 mb-lg-0 position-relative">
                    <button
                      type="button"
                      style={{ width: "100%" }}
                      className={`btn ${!isQPay ? "btn-primary" : "border"}`}
                      onClick={() => setIsQpay(false)}
                    >
                      <i className={`bi bi-check-circle-fill me-3`} />
                      Дансаар
                    </button>
                  </div>
                </div>
              </div>
              {isQPay ? (
                <div className="container">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="border rounded"
                      src="/assets/images/jpg/no-image.jpg"
                      style={{ width: "50%", height: "50%" }}
                    />
                  </div>
                </div>
              ) : (
                <div className="row g-2">
                  <div className="col-md-6 col-12">
                    {/* input */}
                    <div className="mb-3 mb-lg-0">
                      <label className="form-label" htmlFor="nameoncard">
                        Банк
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter name"
                        id="nameoncard"
                        value={account.BANK_NAME}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    {/* input */}
                    <div className="mb-3 mb-lg-0 position-relative">
                      <label className="form-label" htmlFor="expirydate">
                        Дансны дугаар
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="expirydate"
                          value={account.BANK_ACCOUNT}
                          disabled
                          aria-describedby="basic-addon2"
                        />
                        <div className="input-group-append">
                          <CopyToClipboard
                            text={account.BANK_ACCOUNT}
                            onCopy={() =>
                              toast.success("Дансны дугаар хуулагдлаа")
                            }
                          >
                            <button
                              className="btn border rounded-start-0"
                              type="button"
                            >
                              <i className="bi bi-clipboard"></i>
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    {/* input */}
                    <div className="mb-3 mb-lg-0">
                      <label htmlFor="digit-mask" className="form-label">
                        Дансны нэр
                      </label>
                      <input
                        className="form-control"
                        name="digit-mask"
                        id="digit-mask"
                        placeholder="xxx"
                        inputMode="numeric"
                        required
                        value={account.BANK_ACCOUNT_NAME}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    {/* input */}
                    <div className="mb-3 mb-lg-0">
                      <label className="form-label" htmlFor="nameoncard">
                        Шилжүүлэх дүн
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter name"
                          id="nameoncard"
                          value={formatCurrency(totalAmount)}
                          disabled
                        />
                        <div className="input-group-append">
                          <CopyToClipboard
                            text={totalAmount}
                            onCopy={() => toast.success("Үнийн дүн хуулагдлаа")}
                          >
                            <button
                              className="btn border rounded-start-0"
                              type="button"
                            >
                              <i className="bi bi-clipboard"></i>
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    {/* input */}
                    <div className="mb-3 mb-lg-0 position-relative">
                      <label className="form-label" htmlFor="expirydate">
                        Гүйлгээний утга
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="expirydate"
                          placeholder=""
                          value={orderNumber}
                          disabled
                        />
                        <div className="input-group-append">
                          <CopyToClipboard
                            text={orderNumber}
                            onCopy={() =>
                              toast.success("Захиалгын дугаар хуулагдлаа")
                            }
                          >
                            <button
                              className="btn border rounded-start-0"
                              type="button"
                            >
                              <i className="bi bi-clipboard"></i>
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-8 d-flex justify-content-end">
              <button
                className="btn border"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Болих
              </button>
              <button type="submit" className="btn btn-primary ms-3">
                Төлбөр шалгах
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
