import { SHIMMER_COLOR } from "../../utils/constants";

export default function CheckOutShimmerScreen() {
  const shimmerStyle = {
    width: "100%",
    height: 12,
    backgroundColor: SHIMMER_COLOR,
    borderRadius: 25,
  };
  return (
    <section className="my-xxl-8 my-6">
      <div className="container">
        <h4 className="mb-6">Хүргэлтийн мэдээлэл</h4>
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="border rounded p-6">
              <div className="row">
                {[...Array(6)].map((el, i) => (
                  <div
                    className="col-xk-5 col-lg-6 col-xxl-4 col-12 mb-4"
                    key={i}
                    style={{ cursor: "pointer" }}
                  >
                    {/* input */}
                    <div className="card">
                      <div className="card-body p-6">
                        <div
                          className="mb-4"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <i
                            className={`bi bi-check-circle-fill fs-4 me-3`}
                            style={{ color: SHIMMER_COLOR }}
                          />
                          <div style={shimmerStyle}></div>
                        </div>
                        <div style={shimmerStyle}></div>
                        <div style={{ ...shimmerStyle, marginTop: "16px" }} />
                        <div style={{ ...shimmerStyle, marginTop: "16px" }} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* sidebar */}
          <div className="col-12 col-lg-4 col-md-5 mt-6 mt-md-0">
            <div className="card-body">
              {/* heading */}
              <div className="card mb-2">
                {/* list group */}
                <ul className="list-group list-group-flush">
                  {[...Array(8)].map((item, index) => (
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="my-2" style={shimmerStyle}></div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
