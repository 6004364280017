import useMediaQuery from "../../hooks/useMediaQuery";
import { SHIMMER_COLOR } from "../../utils/constants";

export default function CartShimmerScreen() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const shimmerStyle = {
    width: "100%",
    height: 12,
    backgroundColor: SHIMMER_COLOR,
    borderRadius: 25,
  };
  return (
    <section className="my-xxl-8">
      <div className="container">
        <h4 className="mb-6 d-none d-md-block">Миний сагс</h4>
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="border-0 border-md p-md-border rounded p-0 p-md-6">
              <ul className="list-group list-group-flush rounded">
                {[...Array(isMobile ? 6 : 8)].map((el, i) => (
                  <div className="row">
                    <div
                      className="col-2 rounded"
                      style={{
                        padding: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        className="rounded"
                        src={"/assets/images/jpg/no-image.jpg"}
                        alt="Ecommerce"
                        style={{
                          maxWidth: "8rem",
                          maxHeight: "8rem",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    {/* input group */}
                    <div
                      className="col px-0 ps-3 px-md-3 me-3"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div className="row">
                        <div
                          style={{
                            ...shimmerStyle,
                            width: "90%",
                          }}
                        ></div>
                      </div>
                      <div className="row d-none d-md-block">
                        <div
                          style={{
                            ...shimmerStyle,
                            width: "85%",
                          }}
                        ></div>
                      </div>
                      <div className="row mt-1 mt-md-0">
                        <div
                          style={{
                            ...shimmerStyle,
                            width: "80%",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          </div>

          {/* sidebar */}
          <div className="col-12 col-lg-4 col-md-5 mt-6 mt-md-0">
            <div className="card-body">
              {/* heading */}
              <div className="card mb-2">
                {/* list group */}
                <ul className="list-group list-group-flush">
                  {[...Array(8)].map((item, index) => (
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="my-2" style={shimmerStyle}></div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
