export default function TuslamjScreen() {
  return (
    <div className="container">
      <div className="row">
        <div className="col col-md-8 m-auto">
          <h4 className="my-6 mt-8 text-center">Тусламж</h4>
          <img
            src="assets/images/jpg/tuslamj1.png"
            style={{ width: "100%" }}
            className="mb-6"
          />
          <div className="mb-6">
            Манай хаяг: Улаанбаатар, Сүхбаатар дүүрэг, 1-р хороо, Олимпик
            резидэнс 704 тоот
          </div>
          <div className="mb-6">Ажлын цаг: Даваа-Ням 09:00-19:00 цаг </div>
          <div className="mb-6">Утас: 7740-0499</div>
          <div className="mb-14">И-мэйл : info@qmall.mn</div>
        </div>
      </div>
    </div>
  );
}
