import { useNavigate } from "react-router-dom";
import OrderDetailsModal from "../../components/modals/order-details";
import { formatCurrency } from "../../utils/helpers";
import { useMutation } from "@tanstack/react-query";
import { getUserOrders } from "../../api";
import { useEffect, useState } from "react";
import PaymentModal from "./paymentModal";
import { DISABLED_COLOR, PRIMARY_COLOR } from "../../utils/constants";
import OrdersScreenShimmer from "./order-shimmer";
import ReactPaginate from "react-paginate";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useSearchParams } from "react-router-dom";

export default function OrdersScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("p") || 1;
  const isModalOpen = searchParams.get("m") || false;
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (isModalOpen) {
      setShowModal(true);
    }
  }, []);

  const onPageChange = (e) => {
    window.scrollTo(0, 0);
    searchParams.set("p", e.selected + 1);
    setSearchParams(searchParams);
  };
  const {
    isPending,
    mutate: fetchUserOrders,
    data,
  } = useMutation({
    mutationFn: (page) => getUserOrders(page),
    mutationKey: ["getUserOrders"],
  });
  useEffect(() => {
    fetchUserOrders(searchParams.get("p"));
  }, [page]);

  const getDetails = (el) => {
    searchParams.set("i", el.Id);
    setSearchParams(searchParams);
    setShowModal(true);
  };

  return (
    <div className="py-6 p-md-6 p-lg-10">
      {/* heading */}
      <h2>Захиалгууд</h2>
      <div className="row my-3">
        <div className="col">
          {isPending ? (
            <OrdersScreenShimmer />
          ) : (
            <ul className="list-group list-group-flush rounded">
              {data?.Content?.map((el, i) => (
                <li
                  style={{ cursor: "pointer" }}
                  className="list-group-item border my-2 rounded p-md-6"
                  key={i}
                  onClick={() => getDetails(el)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col">
                      <div className="row">
                        <div className="col-6 col-md-3">
                          <small className="text-secondary">
                            Захиалгын дугаар
                          </small>
                          <div className="fw-bold">{el.DisplayId}</div>
                        </div>
                        <div className="col-6 col-md-4 text-end text-md-start">
                          <small className="text-secondary">
                            {el.CreatedDateTime}
                          </small>
                          <div className="fw-bold text-primary text-end text-md-start">
                            {el.StatusName}
                          </div>
                        </div>
                        <div className="col-6 col-md-2">
                          <small className="text-secondary">Үнийн дүн</small>
                          <div className="fw-bold">
                            {formatCurrency(el.TotalAmount)}
                          </div>
                        </div>
                        <div className="col d-flex justify-content-end align-items-center">
                          <i
                            onClick={() => {}}
                            className={`bi bi-arrow-right-circle-fill fs-4`}
                            style={{
                              color: DISABLED_COLOR,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<i className="feather-icon icon-chevron-right" />}
        onPageChange={onPageChange}
        pageRangeDisplayed={3}
        marginPagesDisplayed={isMobile ? 0 : 1}
        pageCount={Math.ceil(data?.TotalCount / 10)}
        previousLabel={<i className="feather-icon icon-chevron-left" />}
        renderOnZeroPageCount={null}
        pageClassName="page-item d-wrap"
        containerClassName="pagination"
        pageLinkClassName="page-link mx-1"
        nextLinkClassName="page-link mx-1 rounded"
        previousLinkClassName="page-link mx-1 rounded"
        activeClassName="active"
        disabledClassName="disabled"
        initialPage={page - 1}
      />
      <OrderDetailsModal showModal={showModal} closeModal={closeModal} />
      <PaymentModal />
    </div>
  );
}
