import { useState } from "react";
import { formatCurrency } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";

export default function CartSideBar({
  totalPrice,
  deliveryPrice,
  totalAmount,
  cart = [],
}) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const submit = () => {
    const products = [];
    for (let i = 0; i < cart?.length; i++) {
      const element = cart[i];
      if (element.isChecked) {
        const weight = element?.Fields?.filter((e) => e.Name == "Weight") || 0;
        products.push({
          Id: element.ItemId,
          ConfigurationId: element.ConfigurationId,
          Quantity: element.Quantity,
          Weight: parseInt(weight[0].Value),
        });
      }
    }
    const arr = {
      TotalPrice: totalPrice,
      DeliveryPrice: deliveryPrice,
      TotalAmount: totalAmount,
      Products: products,
    };

    navigate("/checkout", {
      state: {
        data: arr,
        fromCart: true,
      },
    });
  };

  return (
    <div className="card-body">
      {/* heading */}
      <div className="card mb-2">
        {/* list group */}
        <ul className="list-group list-group-flush">
          {/* list group item */}
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="me-auto">
              <div>Барааны үнэ</div>
            </div>
            <span>{formatCurrency(totalPrice)}</span>
          </li>
          {/* list group item */}
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="me-auto">
              <div>Хятад доторх хүргэлт</div>
            </div>
            <span>{formatCurrency(deliveryPrice)}</span>
          </li>
          {/* list group item */}
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="me-auto">
              <div className="fw-bold">Төлөх дүн</div>
            </div>
            <span className="fw-bold">{formatCurrency(totalAmount)}</span>
          </li>
        </ul>
      </div>
      <div className="d-grid mb-1 mt-4">
        {loading ? (
          <button className="btn btn-primary" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></span>
            Түр хүлээнэ үү...
          </button>
        ) : (
          <button
            className={`btn btn-primary btn-lg`}
            type="submit"
            loading={false}
            disabled={totalAmount == 0}
            onClick={submit}
            data-bs-dismiss={isMobile && "offcanvas"}
            aria-label={isMobile && "Close"}
          >
            Захиалга хийх
          </button>
        )}
      </div>
      {/* text */}
    </div>
  );
}
