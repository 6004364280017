import MenuSlider from "../../components/menu-slider";
import useFetch from "../../hooks/useFetch";
import Carousel from "./carousel";
import Products from "../../components/products";
import { useQuery } from "@tanstack/react-query";
import SectionTitle from "../../components/title";
import { api } from "../../api";

export default function HomeScreen() {
  const { isLoading, isError, data } = useQuery({
    queryFn: async () => {
      const { data } = await api.post("/getSuggestedProducts");
      return data?.data;
    },
    queryKey: ["getSuggestedProducts"],
  });

  return (
    <div>
      <Carousel />
      <MenuSlider />
      <div className="container">
        <SectionTitle title={"Санал болгох"} />
        <Products
          data={data?.best}
          loading={isLoading}
          showFilter={false}
          showPagination={false}
          cols={5}
        />
        <div className="mt-6" />
        <SectionTitle title={"Сүүлд нэмэгдсэн"} />
        <Products
          data={data?.last}
          loading={isLoading}
          showFilter={false}
          showPagination={false}
          cols={5}
        />
        <div className="mt-6" />
        <SectionTitle title={"Эрэлттэй"} />
        <Products
          data={data?.popular}
          loading={isLoading}
          showFilter={false}
          showPagination={false}
          cols={5}
        />
        <div className="mb-14"></div>
      </div>
    </div>
  );
}
