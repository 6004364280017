import { useMutation } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import { deleteUserProfile } from "../../../api";
import { toast } from "react-toastify";

export default function UserProfileAddressDeleteModal({ show, hide, id }) {
  const { isPending, mutate: deleteProfile } = useMutation({
    mutationFn: () => deleteUserProfile(id),
    mutationKey: ["deleteUserProfile"],
    onSuccess: (data) => {
      if (data.ErrorCode === "Ok") {
        window.location.reload();
        toast.success("Амжилттай устгалаа");
      } else {
        toast.error("Алдаа гарлаа");
      }
    },
  });

  return (
    <Modal show={show} centered>
      <Modal.Body>
        <div className="modal-header border-0">
          <h5 className="modal-title fs-3 fw-bold" id="logoutModalLabel">
            Анхааруулга
          </h5>
          <button type="button" className="btn-close" onClick={hide} />
        </div>
        <div className="modal-body">
          <span>Хүргэлтийн хаягаа устгахдаа итгэлтэй байна уу?</span>
        </div>
        <div className="mt-8 d-flex justify-content-end">
          <button className="btn border" type="button" onClick={hide}>
            Болих
          </button>
          {!isPending ? (
            <button
              type="submit"
              className="btn btn-primary ms-3"
              onClick={deleteProfile}
            >
              Устгах
            </button>
          ) : (
            <button type="submit" className="btn btn-primary ms-3" disabled>
              <span
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></span>
              Түр хүлээнэ үү...
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
