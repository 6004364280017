import { useMutation } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import { createUserProfileInfo, deleteUserProfile } from "../../../api";
import { toast } from "react-toastify";
import UserInfoComponent from "./user-info-component";
import { useState } from "react";

export default function UserProfileAddAddressModal({ show, hide, id }) {
  const [profile, setProfile] = useState({
    FirstName: "",
    LastName: "",
    Region: "",
    City: "",
    Address: "",
    Phone: "",
    Id: "",
    ExternalDeliveryId: "f0772e",
  });

  const { isPending: creatingProfile, mutate: createProfile } = useMutation({
    mutationFn: () => createUserProfileInfo(profile),
    mutationKey: ["createUserProfileInfo"],
    onSuccess: (data) => {
      if (data.ErrorCode === "Ok") {
        window.location.reload();
        toast.success("Амжилттай хадгалагдлаа");
      } else {
        toast.error(data.ErrorMessage || "Алдаа гарлаа");
      }
    },
  });

  return (
    <Modal show={show} centered size="xl">
      <Modal.Body>
        <div className="modal-header border-0">
          <h5 className="modal-title fs-3 fw-bold" id="logoutModalLabel">
            Шинэ хаяг нэмэх
          </h5>
          <button type="button" className="btn-close" onClick={hide} />
        </div>
        <div className="modal-body">
          <UserInfoComponent profile={profile} setProfile={setProfile} />
        </div>
        <div className="mt-8 d-flex justify-content-end">
          <button className="btn border" type="button" onClick={hide}>
            Болих
          </button>
          {!creatingProfile ? (
            <button
              type="submit"
              className="btn btn-primary ms-3"
              onClick={createProfile}
            >
              Хадгалах
            </button>
          ) : (
            <button type="submit" className="btn btn-primary ms-3" disabled>
              <span
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></span>
              Түр хүлээнэ үү...
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
