import { useState, useEffect } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";

export default function ProductImageSlide({ data }) {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    let items = [];
    const videos = data.Videos || [];
    const pictures = data.Pictures || [];

    if (videos?.length) {
      for (let i = 0; i < videos.length; i++) {
        const element = videos[i];
        items.push({
          type: "video",
          src: element.Url,
        });
      }
    }
    if (pictures?.length) {
      for (let i = 0; i < pictures.length; i++) {
        const element = pictures[i];
        items.push({
          type: "image",
          src: element.Large.Url,
        });
      }
    }
    setImages(items);
  }, []);
  return (
    <div>
      {images.length > 0 && (
        <div
          className="product"
          id="product"
          style={{ height: isMobile ? "20rem" : "30rem" }}
        >
          {images[index].type == "image" ? (
            <img
              src={images[index].src}
              alt
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <video
              controls
              autoPlay={true}
              loop
              muted
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            >
              <source src={images[index].src} type="video/mp4" />
            </video>
          )}
        </div>
      )}
      {/* product tools */}
      <div className="product-tools">
        <div className="thumbnails row g-3" id="productThumbnails">
          {images.map((k, i) => (
            <div className="col-2">
              <div className="thumbnails-img border rounded">
                <img
                  onClick={() => setIndex(i)}
                  src={
                    k.type == "video" ? "/assets/images/jpg/play.jpg" : k.src
                  }
                  className={`rounded ${
                    index == i && "border border-danger border-3"
                  }`}
                  alt={i}
                  style={{
                    width: "100%",
                    height: isMobile ? "3rem" : "5rem",
                    objectFit: "cover",
                  }}
                ></img>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
