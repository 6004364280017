import useMediaQuery from "../../hooks/useMediaQuery";
import { searchProducts } from "../../api";
import Products from "../../components/products";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

export default function SearchScreen() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [searchParams, setSearchParams] = useSearchParams();
  const image = searchParams.get("image") || "";
  const q = searchParams.get("q") || "";
  const page = searchParams.get("p") || 1;
  const sort = searchParams.get("sort") || "";

  const { isLoading, isError, data } = useQuery({
    queryFn: () => searchProducts(q, page, sort, image),
    queryKey: ["searchProducts", q, page, sort, image],
  });
  return (
    <main>
      <div className="mt-8 mb-14">
        <div className="container">
          <div className="row">
            <section className="col">
              <Products
                data={data?.Items?.Content}
                loading={isLoading}
                showFilter={true}
                showPagination={true}
                cols={5}
                pageCount={data?.MaximumPageCount}
                totalCount={data?.Items.TotalCount}
              />
            </section>
          </div>
        </div>
      </div>
    </main>
  );
}
