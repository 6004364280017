import parse, { attributesToProps } from "html-react-parser";
import useMediaQuery from "../../hooks/useMediaQuery";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import Products from "../../components/products";

export default function ProductDetailedInfo({
  data,
  loading,
  error,
  products,
}) {
  const { id } = useParams();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const {
    data: data2,
    loading: loading2,
    error: error2,
  } = useFetch(`getProductDescriptions?itemId=${id}`);
  const descriptions = data2?.ItemDescription || [];
  return (
    <div className="col-md-12">
      <ul className="nav nav-pills nav-lb-tab" id="myTab" role="tablist">
        {/* nav item */}
        <li className="nav-item" role="presentation">
          {/* btn */}
          <button
            className="nav-link active"
            id="product-tab"
            data-bs-toggle="tab"
            data-bs-target="#product-tab-pane"
            type="button"
            role="tab"
            aria-controls="product-tab-pane"
            aria-selected="true"
          >
            Тайлбар
          </button>
        </li>
        {/* nav item */}
        <li className="nav-item" role="presentation">
          {/* btn */}
          <button
            className="nav-link"
            id="details-tab"
            data-bs-toggle="tab"
            data-bs-target="#details-tab-pane"
            type="button"
            role="tab"
            aria-controls="details-tab-pane"
            aria-selected="false"
          >
            Барааны мэдээлэл
          </button>
        </li>
        {/* nav item */}
        <li className="nav-item" role="presentation">
          {/* btn */}
          <button
            className="nav-link"
            id="reviews-tab"
            data-bs-toggle="tab"
            data-bs-target="#reviews-tab-pane"
            type="button"
            role="tab"
            aria-controls="reviews-tab-pane"
            aria-selected="false"
          >
            Төстэй бараанууд
          </button>
        </li>
      </ul>
      {/* tab content */}
      <div className="tab-content" id="myTabContent">
        {/* tab pane */}
        <div
          className="tab-pane fade show active"
          id="product-tab-pane"
          role="tabpanel"
          aria-labelledby="product-tab"
          tabIndex={0}
        >
          <div className="my-8">
            {!loading2 && descriptions.length > 0 && (
              <div className="row">
                {parse(descriptions, {
                  transform(reactNode, domNode, index) {
                    return (
                      <div
                        className={`html-img-${
                          isMobile ? "mobile" : "desktop"
                        }`}
                      >
                        {reactNode}
                      </div>
                    );
                  },
                })}
              </div>
            )}
          </div>
        </div>
        {/* tab pane */}
        <div
          className="tab-pane fade"
          id="details-tab-pane"
          role="tabpanel"
          aria-labelledby="details-tab"
          tabIndex={0}
        >
          <div className="my-8">
            <div className="row">
              <div className="col-12 col-lg-6">
                <table className="table table-striped">
                  {/* table */}
                  <tbody>
                    {data?.Properties?.map((el) => (
                      <tr>
                        <th>{el.DisplayName}</th>
                        <td>
                          <div className="d-flex flex-wrap justify-content-start m-0">
                            {el.Value.map((b, i) => (
                              <small>{`${b.DisplayName} ${
                                i < el.Value.length - 1 ? "," : ""
                              }`}</small>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* tab pane */}
        <div
          className="tab-pane fade"
          id="reviews-tab-pane"
          role="tabpanel"
          aria-labelledby="reviews-tab"
          tabIndex={0}
        >
          {!loading && <Products cols={5} data={products} />}
        </div>
        {/* tab pane */}
      </div>
    </div>
  );
}
