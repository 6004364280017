import { useQuery } from "@tanstack/react-query";
import { getDeliveryModes } from "../../../api";

export default function UserInfoComponent({ profile, setProfile }) {
  const { data, isLoading, isError } = useQuery({
    queryFn: () => getDeliveryModes(),
    queryKey: ["getDeliveryModes"],
  });

  return (
    <div>
      <div className="row">
        {!isLoading &&
          data.map((e, i) => (
            <div
              className="d-flex justify-content-between align-items-center mb-6"
              key={i}
            >
              <div>
                <div className="mb-1 text-black">{e.Name}</div>
                <p className="mb-0 text-secondary">
                  <small>{e.Description}</small>
                </p>
              </div>
              {/* checkbox */}
              <div className="form-check form-switch">
                <input
                  checked={profile.ExternalDeliveryId === e.Id}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  onChange={() =>
                    setProfile((prevState) => ({
                      ...prevState,
                      ExternalDeliveryId: e.Id,
                    }))
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckDefault"
                />
              </div>
            </div>
          ))}
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-label text-secondary" htmlFor="lname">
            Овог
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="lname"
            className="form-control"
            name="lname"
            required
            defaultValue={profile.LastName}
            onChange={(e) =>
              setProfile((prevState) => ({
                ...prevState,
                LastName: e.target.value,
              }))
            }
          />
          <div className="invalid-feedback">Овогоо бичнэ үү</div>
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label text-secondary" htmlFor="fname">
            Нэр
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="fname"
            className="form-control"
            name="fname"
            required
            defaultValue={profile.FirstName}
            onChange={(e) =>
              setProfile((prevState) => ({
                ...prevState,
                FirstName: e.target.value,
              }))
            }
          />
          <div className="invalid-feedback">Нэрээ бичнэ үү</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-label text-secondary" htmlFor="city">
            Хот/Аймаг
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="city"
            className="form-control"
            name="city"
            required
            defaultValue={profile.City}
            onChange={(e) =>
              setProfile((prevState) => ({
                ...prevState,
                City: e.target.value,
              }))
            }
          />
          <div className="invalid-feedback">Хот/Аймаг бичнэ үү</div>
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label text-secondary" htmlFor="region">
            Хороо/Баг
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="region"
            className="form-control"
            name="region"
            required
            defaultValue={profile.Region}
            onChange={(e) =>
              setProfile((prevState) => ({
                ...prevState,
                Region: e.target.value,
              }))
            }
          />
          <div className="invalid-feedback">Заавал бөглөнө</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-label text-secondary" htmlFor="address">
            Хаяг
          </label>
          <input
            type="text"
            id="address"
            className="form-control"
            name="address"
            required
            defaultValue={profile.Address}
            onChange={(e) =>
              setProfile((prevState) => ({
                ...prevState,
                Address: e.target.value,
              }))
            }
          />
          <div className="invalid-feedback">Заавал бөглөнө</div>
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label text-secondary" htmlFor="phone">
            Утасны дугаар
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="phone"
            className="form-control"
            name="phone"
            required
            defaultValue={profile.Phone}
            onChange={(e) =>
              setProfile((prevState) => ({
                ...prevState,
                Phone: e.target.value,
              }))
            }
          />
          <div className="invalid-feedback">Заавал бөглөнө</div>
        </div>
      </div>
    </div>
  );
}
