import Drawer from "./drawer";
import useAuthentication from "../../hooks/useAuthentication";
import SignIn from "../modals/signIn";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import imageCompression from "browser-image-compression";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { formatCurrency, randomTenDigit } from "../../utils/helpers";
import MegaMenu from "./mega-menu";

export default function Header() {
  const [uploading, setuploading] = useState(false);
  const { isLoggedIn } = useAuthentication();
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const onChange = (e) => {
    // setText(e.target.value);
    setText(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?q=${text}`, { replace: true });
    // window.location.reload();
  };

  async function handleImage(e) {
    setuploading(true);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const imageFile = e.target.files[0];
      const compressedFile = await imageCompression(imageFile, options);
      const formData = new FormData();
      const randomDigit = randomTenDigit();
      const url = `${process.env.REACT_APP_BASE_URL}uploadPhoto?num=${randomDigit}`;
      formData.append("file", compressedFile);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await axios.post(url, formData, config).then((response) => {
        if (response.data.success) {
          const name = response.data.path.split("/search/");
          navigate(`/search?image=${name[1]}`);
        }
      });
    } catch (error) {}
    setuploading(false);
  }

  return (
    <div>
      <div
        className="d-none d-lg-block"
        style={{ backgroundColor: "#FE4F4F", color: "white" }}
      >
        <div className="container">
          <div className="row py-3">
            <div className="col-xl-9 col-lg-4 col-5 d-md-flex align-items-center justify-content-start">
              <a href="/" className="text-reset">
                Нүүр
              </a>
              <a href="/zaavar" className="mx-md-8 ms-4 text-reset">
                Заавар
              </a>
              <a href="/tuslamj" className="mx-md-8 ms-4 text-reset">
                Тусламж
              </a>
              <a href="/asuult" className="mx-md-8 ms-4 text-reset">
                Асуулт
              </a>

              {/* Button */}
            </div>
            <div className="col-xl-3 col-lg-8 col-7 d-flex justify-content-end">
              {isLoggedIn ? (
                <div className="mx-md-8 ms-4 text-reset">
                  CNY- {formatCurrency(465.2)}
                </div>
              ) : (
                <>
                  <a
                    href="#"
                    data-bs-toggle={"modal"}
                    data-bs-target={"#signInModal"}
                    className="mx-md-8 ms-4 text-reset"
                  >
                    Нэвтрэх
                  </a>
                  <a
                    href="#"
                    data-bs-toggle={"modal"}
                    data-bs-target={"#signUpModal"}
                    className="mx-md-8 ms-4 text-reset"
                  >
                    Бүртгүүлэх
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center pt-6 pb-md-4 mt-0">
          <div className="col-xl-3 col-md-3 d-none d-lg-block mb-4 mb-md-0">
            <div className="d-flex justify-content-between align-items-center">
              <button
                className="btn btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                href="#offcanvasExample"
                role="button"
                aria-controls="offcanvasExample"
              >
                <i className="bi bi-list me-3" style={{ color: "white" }} />
                Ангилал
              </button>
              <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                <img src="/assets/images/svg/logo.svg" alt="QMall logo" />
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-5 col-lg-6 col-md-9">
            <form onSubmit={onSubmit}>
              <div className="input-group z-0 input-group-search">
                <input
                  className="form-control rounded-start-pill border-end-0"
                  type="search"
                  placeholder="Хайх зүйлээ бичнэ үү"
                  aria-describedby="button-addon2"
                  onChange={onChange}
                />
                <div
                  className="border-top border-bottom"
                  style={{
                    width: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label for="fileUpload">
                    {uploading ? (
                      <RotatingLines
                        strokeColor="#102a37"
                        strokeWidth="3"
                        animationDuration="0.7"
                        width="1.5rem"
                        visible={true}
                      />
                    ) : (
                      <div
                        type="file"
                        className="btn btn-primary rounded-circle p-0"
                        id="button-addon2"
                      >
                        <i className="bi bi-camera m-1"></i>
                      </div>
                    )}
                  </label>
                  <input
                    type="file"
                    id="fileUpload"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={handleImage}
                  />
                </div>
                <button
                  className="btn rounded-end-pill"
                  type="submit"
                  id="button-addon2"
                  style={{ background: "#FE4F4F", color: "white" }}
                >
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </form>
          </div>
          <div className="col-xxl-3 col-xl-5 col-lg-3 d-none d-lg-block">
            <div className="d-flex align-items-center justify-content-evenly ms-4">
              <div className="text-center">
                <a
                  href="/profile/info"
                  className="text-reset"
                  data-bs-toggle={isLoggedIn ? "" : "modal"}
                  data-bs-target={isLoggedIn ? "" : "#signInModal"}
                >
                  <div className="lh-1">
                    <div className="mb-2">
                      <i className="bi bi-person-circle fs-4" />
                    </div>
                    <p className="mb-0 d-none d-xl-block small">Профайл</p>
                  </div>
                </a>
              </div>
              <div className="ms-6 text-center">
                <a
                  href="../profile/orders"
                  className="text-reset"
                  data-bs-toggle={isLoggedIn ? "" : "modal"}
                  data-bs-target={isLoggedIn ? "" : "#signInModal"}
                >
                  <div className="lh-1">
                    <div className="mb-2">
                      <i className="bi bi-archive fs-4" />
                    </div>
                    <p className="mb-0 d-none d-xl-block small">Захиалга</p>
                  </div>
                </a>
              </div>
              <div className="ms-6 text-center">
                <div className="dropdown">
                  <a
                    href="/basket"
                    className="text-reset"
                    data-bs-toggle={isLoggedIn ? "" : "modal"}
                    data-bs-target={isLoggedIn ? "" : "#signInModal"}
                  >
                    <div className="lh-1">
                      <div className="position-relative d-inline-block mb-2">
                        <i className="bi bi-cart fs-4" />
                        {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          1
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span> */}
                      </div>
                      <p className="mb-0 d-none d-xl-block small">Сагс</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignIn />
      <Drawer />
    </div>
  );
}
