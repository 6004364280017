import CartScreen from "../../screens/cart";

export default function MobileCartScreen() {
  return (
    <div id="sidebar">
      <div className="sidebar__inner">
        <div
          className="offcanvas offcanvas-start offcanvas-collapse"
          tabIndex={-1}
          id="offcanvasCartMobile"
          aria-labelledby="offcanvasCartMobileLabel"
        >
          <div className="offcanvas-header d-lg-none">
            <div className="text-start">
              <h5 id="offcanvasCartMobileLabel" className="mb-0 fs-4">
                Сагс
              </h5>
            </div>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body p-0">
            <div className="mb-8 text-start">
              <CartScreen refresh={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
