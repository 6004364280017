import { useState } from "react";
import "./App.css";
import ProductDetailsScreen from "./screens/product-details";
import { Routes, Route } from "react-router-dom";
import CategoryScreen from "./screens/category";
import Header from "./components/header";
import BottomNav from "./components/bottomNav";
import ProfileScreen from "./screens/profile";
import Footer from "./components/footer";
import HomeScreen from "./screens/home";
import "react-toastify/dist/ReactToastify.css";
import CartScreen from "./screens/cart";
import SearchScreen from "./screens/search/index.js";
import BrandScreen from "./screens/brand/index.js";
import ZaavarScreen from "./screens/zaavar/index.js";
import TuslamjScreen from "./screens/tuslamj/index.js";
import AsuultScreen from "./screens/asuult/index.js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CheckOutNewScreen from "./screens/check-out-new/index.js";

//

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      retry: 3,
      staleTime: 1000 * 60 * 60 * 4, // 4 hours
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
      suspense: false,
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <Header />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/basket" element={<CartScreen />} />
        <Route path="/checkout" element={<CheckOutNewScreen />} />
        <Route path="/product/:id" element={<ProductDetailsScreen />} />
        <Route
          path="/category/:cat1/:cat2/:cat3"
          element={<CategoryScreen />}
        />
        <Route path="/profile/:screen" element={<ProfileScreen />} />
        <Route path="/search" element={<SearchScreen />} />
        <Route path="/brand/:id" element={<BrandScreen />} />
        <Route path="/zaavar" element={<ZaavarScreen />} />
        <Route path="/tuslamj" element={<TuslamjScreen />} />
        <Route path="/asuult" element={<AsuultScreen />} />
      </Routes>
      <Footer />
      <BottomNav />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
