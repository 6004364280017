import useFetch from "../../hooks/useFetch";
import { changeCatName } from "../../utils/helpers";
import Icons from "../../mock/icons";
import { useQuery } from "@tanstack/react-query";
import { getCategoryList } from "../../api";

export default function Drawer() {
  const { isLoading, isError, data } = useQuery({
    queryFn: getCategoryList,
    queryKey: ["getCategoryList"],
  });

  return (
    <div
      className="offcanvas offcanvas-start w-25"
      data-bs-scroll="false"
      data-bs-backdrop="true"
      tabIndex={-1}
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
          Ангилал
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div class="offcanvas-body">
        <ul class="list-unstyled">
          {!isLoading &&
            data?.map((e, i) => (
              <li class="dropdown-menu-list" key={i}>
                <a
                  href="javascript:void(0)"
                  class="dropdown-item d-flex justify-content-between mb-1 py-1"
                >
                  <div className="d-flex align-items-center">
                    {<Icons id={e.Id} />}
                    <span class="ms-1">{e.Name}</span>
                  </div>
                  <div>
                    <i class="feather-icon icon-chevron-right"></i>
                  </div>
                </a>
                <div
                  class="dropdown-menu-list-submenu border-radius-changer"
                  style={{ height: "100%", overflowX: "hidden" }}
                >
                  {e.Children?.map((e1, i1) => (
                    <div
                      class="p-2 p-lg-4"
                      key={i1}
                      style={{ maxWidth: "15rem" }}
                    >
                      <h6 class="text-primary ps-3">{e1.Name}</h6>
                      <ul class="list-unstyled">
                        {e1.Children?.map((e2, i2) => (
                          <li
                            key={i2}
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: 12,
                              textOverflow: "ellipsis",
                            }}
                          >
                            <a
                              href={`/category/${changeCatName(
                                e.Id
                              )}/${changeCatName(e1.Id)}/${changeCatName(
                                e2.Id
                              )}`}
                              class="dropdown-item"
                            >
                              {e2.Name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </li>
            ))}
        </ul>
        {/* Store list */}
      </div>
    </div>
  );
}
