import { capitalizeFirstLetter, formatCurrency } from "../../utils/helpers";
import { DISABLED_COLOR, PRIMARY_COLOR } from "../../utils/constants";
import ChangeConfigModal from "./changeConfigModal";

export default function CartItem({
  element,
  checkItem,
  deleteItem,
  changeItemQuantity,
}) {
  return (
    <div className="row">
      <div className="col-1">
        <div
          className="d-flex justify-content-end justify-content-md-center align-items-center"
          style={{ height: "100%" }}
        >
          <i
            onClick={() => {
              checkItem(element.Id);
            }}
            className={`bi bi-check-circle-fill fs-4`}
            style={{
              color: element.isChecked ? PRIMARY_COLOR : DISABLED_COLOR,
            }}
          />
        </div>
      </div>

      <div
        className="col-2 rounded"
        style={{
          padding: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {element?.Fields?.map(
          (e, index) =>
            e.Name === "PictureURL" && (
              <img
                className="rounded"
                key={index}
                src={e.Value}
                alt="Ecommerce"
                style={{
                  maxWidth: "8rem",
                  maxHeight: "8rem",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )
        )}
      </div>

      {/* input group */}
      <div
        className="col px-0 ps-3 px-md-3"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <div className="row">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <div className="text-inherit">
              {element.Fields.map(
                (e, index) =>
                  e.Name === "ItemTitle" && (
                    <h6
                      className="mb-0"
                      key={index}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        lineHeight: "20px",
                        maxHeight: "100px",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {capitalizeFirstLetter(e.Value)}
                    </h6>
                  )
              )}
            </div>
            <button
              onClick={() => deleteItem(element.Id)}
              className="btn btn-sm border"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {element.isDeleting ? (
                <span
                  className="spinner-border spinner-border-sm text-secondary"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <i className="bi bi-trash fs-6"></i>
              )}
            </button>
          </div>
        </div>
        <div className="row d-none d-md-block">
          <div className="d-flex align-items-center">
            <small className="me-3">Сонгосон:</small>
            {element.Configuration?.Configurator?.map((k, i) => (
              <div
                key={i}
                onClick={() => {}}
                // data-bs-toggle={"modal"}
                // data-bs-target={"#changeConfigModal"}
                className={"btn btn-sm border rounded m-1"}
                style={{
                  display: "inline-block",
                  maxWidth: "5rem",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {k.Value}
              </div>
            ))}
          </div>
        </div>
        <div className="row mt-1 mt-md-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="col">
              <small className="text-secondary me-3">Нийт дүн:</small>
              <span className="fw-bold" style={{ color: PRIMARY_COLOR }}>
                {formatCurrency(
                  parseInt(element.Price) * parseInt(element.Quantity)
                )}
              </span>
            </div>
            <div className="">
              {element.isChangingQty ? (
                <div
                  className="p-2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    className="spinner-border spinner-border-sm text-secondary"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <div className="input-group input-spinner">
                  <input
                    disabled={parseInt(element.Quantity) == 1}
                    type="button"
                    defaultValue="-"
                    className="button-minus btn btn-sm"
                    data-field="quantity"
                    onClick={() => {
                      changeItemQuantity(element.Id, false);
                    }}
                  />
                  <input
                    type="number"
                    step={1}
                    value={element.Quantity}
                    name="quantity"
                    className="quantity-field form-control-sm form-input"
                  />
                  <input
                    type="button"
                    defaultValue="+"
                    className="button-plus btn btn-sm"
                    data-field="quantity"
                    onClick={() => {
                      changeItemQuantity(element.Id, true);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row d-block d-md-none mt-3">
        <div
          className="d-flex align-items-center"
          style={{ display: "flex", justifyContent: "right" }}
        >
          <small className="me-3">Сонгосон:</small>
          {element.Configuration?.Configurator?.map((k, i) => (
            <div
              key={i}
              onClick={() => {}}
              data-bs-toggle={"modal"}
              data-bs-target={"#changeConfigModal"}
              className={"btn btn-sm border rounded m-1"}
              style={{
                display: "inline-block",
                maxWidth: "5rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {k.Value}
            </div>
          ))}
        </div>
      </div>
      {/* <ChangeConfigModal element={element} /> */}
    </div>
  );
}
