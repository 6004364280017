export const formatCurrency = (number, symbol = "₮") => {
  const formattedNumber = Math.ceil(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${formattedNumber}${symbol}`;
};

export function convertToInt(number) {
  let string = number.toString();
  let spaceRemovedString = string.replace(/ /g, "");
  number = parseInt(spaceRemovedString);
  number = Math.ceil(number);
  return number;
}

export function randomTenDigit() {
  return Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000;
}

export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export function saveToken(token) {
  localStorage.setItem("token", token);
}
export function getToken() {
  const token = localStorage.getItem("token");
  return token;
}
export function removeToken() {
  localStorage.removeItem("token");
}

export function changeCatName(id) {
  const newId = id.split("otc-");
  return newId[1];
}
