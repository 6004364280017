import { Rating } from "@smastrom/react-rating";
import { useNavigate } from "react-router-dom";

export default function ProductVendor({ vendor }) {
  const navigate = useNavigate();

  return (
    <div
      className="card mt-6 bg-light border-0"
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/brand/${vendor.Id}`)}
    >
      <div className="row p-3">
        <div
          className="col"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={vendor?.DisplayPictureUrl || "/assets/images/png/taobao.png"}
            className="rounded-circle m-3"
            style={{ width: "50%", objectFit: "cover" }}
          />
        </div>
        <div
          className="col"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <div>Дэлгүүрийн нэр</div>
          <div className="text-uppercase">{vendor.DisplayName}</div>
          <Rating value={4} halfFillMode="box" />
        </div>
        {vendor.Features.map((el, i) => {
          if (el.Id === "DeliveryScore") {
            return (
              <div
                className="col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                }}
              >
                <div>{el.DisplayName}</div>
                <div className="text-uppercase">{el.Value}</div>
              </div>
            );
          }
          if (el.Id === "ItemScore") {
            return (
              <div
                className="col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                }}
              >
                <div>{el.DisplayName}</div>
                <div className="text-uppercase">{el.Value}</div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
