import { formatCurrency } from "../../utils/helpers";

export default function ProductPrice({ isLoading, data }) {
  return (
    <div className="mt-6">
      <div className="fs-4">
        {!isLoading ? (
          data?.Configuration?.Current?.OldPrice && (
            <span className="text-decoration-line-through text-muted">
              {formatCurrency(data?.Configuration?.Current?.OldPrice?.Base)}
            </span>
          )
        ) : (
          <div
            className="mb-3"
            style={{
              backgroundColor: "#ecf0ef",
              height: 32,
              width: "10rem",
              borderRadius: 4,
            }}
          />
        )}

        {data?.Configuration?.Current?.DiscountPercent && (
          <span>
            <small className="ms-2 fs-5 badge bg-danger rounded-pill">
              -{data?.Configuration?.Current?.DiscountPercent}%
            </small>
          </span>
        )}
      </div>
      {!isLoading ? (
        data?.Configuration?.Current?.Price && (
          <span className="fw-bold text-dark fs-4">
            {formatCurrency(data?.Configuration?.Current?.Price?.Base)}
          </span>
        )
      ) : (
        <div
          style={{
            backgroundColor: "#ecf0ef",
            height: 32,
            width: "10rem",
            borderRadius: 4,
          }}
        />
      )}
    </div>
  );
}
