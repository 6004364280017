import { useState } from "react";
import SignUp from "./signup";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function LogoutModal() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("basket");
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <div
        className="modal fade"
        id="logoutModal"
        tabIndex={-1}
        aria-labelledby="logoutModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-4">
            <div className="modal-header border-0">
              <h5 className="modal-title fs-3 fw-bold" id="logoutModalLabel">
                Анхааруулга
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <span>Та системээс гарахдаа итгэлтэй байна уу?</span>
            </div>
            <div className="mt-8 d-flex justify-content-end">
              <button
                className="btn border"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Болих
              </button>
              <button
                type="submit"
                className="btn btn-primary ms-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={logout}
              >
                Гарах
              </button>
            </div>
          </div>
        </div>
      </div>
      <SignUp />
    </>
  );
}
