export default function EditScreen() {
  return (
    <div className="col-lg-9 col-md-8 col-12">
      <div className="py-6 p-md-6 p-lg-10">
        <div className="mb-6">
          {/* heading */}
          <h2 className="mb-0">Мэдээлэл өөрчлөх</h2>
        </div>
        <div>
          {/* heading */}
          <h5 className="mb-4">Хэрэглэгчийн мэдээлэл</h5>
          <div className="row">
            {/* form */}
            <form className="row row-cols-1 row-cols-lg-2">
              {/* input */}
              <div className="mb-3">
                <label className="form-label">Овог</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Нэвтрэх нэр"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Нэр</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Нэвтрэх нэр"
                />
              </div>
              {/* input */}
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="example@gmail.com"
                />
              </div>
              {/* input */}
              <div className="mb-3">
                <label className="form-label">Skype</label>
                <input type="text" className="form-control" placeholder="" />
              </div>
              <div className="mb-3">
                <label className="form-label">Нууц үг</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="**********"
                />
              </div>
              <div className="mb-3"></div>
              {/* button */}
              <div className="mb-3">
                <button className="btn btn-primary">Хадгалах</button>
              </div>
            </form>
          </div>
        </div>
        <hr className="my-10" />
        <div className="pe-lg-14">
          {/* heading */}
          <h5 className="mb-4">Утасны дугаар</h5>
          <form className="row row-cols-1 row-cols-lg-2">
            {/* input */}
            <div className="mb-3 col">
              <label className="form-label">Хуучин утасны дугаар</label>
              <input
                type="password"
                className="form-control"
                placeholder="**********"
              />
            </div>
            <div className="mb-3 col">
              <label className="form-label">Шинэ утасны дугаар</label>
              <input
                type="password"
                className="form-control"
                placeholder="**********"
              />
            </div>
            {/* input */}
            <div className="mb-3 col">
              <label className="form-label">Нууц үг</label>
              <input
                type="password"
                className="form-control"
                placeholder="**********"
              />
            </div>
            <div className="mb-3 col"></div>
            {/* input */}
            <div className="col-12">
              {/* <p className="mb-4">
                Can’t remember your current password?
                <a href="#">Reset your password.</a>
              </p> */}
              <a href="#" className="btn btn-primary">
                Хадгалах
              </a>
            </div>
          </form>
        </div>
        <hr className="my-10" />
        <div className="pe-lg-14">
          {/* heading */}
          <h5 className="mb-4">Нууц үг</h5>
          <form className="row row-cols-1 row-cols-lg-2">
            {/* input */}
            <div className="mb-3 col">
              <label className="form-label">Шинэ нууц үг</label>
              <input
                type="password"
                className="form-control"
                placeholder="**********"
              />
            </div>
            <div className="mb-3 col">
              <label className="form-label">Шинэ нууц үг давтах</label>
              <input
                type="password"
                className="form-control"
                placeholder="**********"
              />
            </div>
            {/* input */}
            <div className="mb-3 col">
              <label className="form-label">Хуучин нууц үг</label>
              <input
                type="password"
                className="form-control"
                placeholder="**********"
              />
            </div>
            <div className="mb-3 col"></div>
            {/* input */}
            <div className="col-12">
              {/* <p className="mb-4">
                Can’t remember your current password?
                <a href="#">Reset your password.</a>
              </p> */}
              <a href="#" className="btn btn-primary">
                Хадгалах
              </a>
            </div>
          </form>
        </div>
        <hr className="my-10" />
        <div>
          {/* heading */}
          <h5 className="mb-4">Бүртгэлээ устгах</h5>
          <p className="mb-2 text-danger">Анхааруулга</p>
          <p className="mb-5">
            Бүртгэлээ устгаснаар таны захиалгууд болон хувийн мэдээлэл устахыг
            анхаарна уу
          </p>
          {/* btn */}
          <a href="#" className="btn btn-outline-danger">
            Устгах
          </a>
        </div>
      </div>
    </div>
  );
}
