import { useNavigate } from "react-router-dom";
import OrderDetailsModal from "../../components/modals/order-details";
import useFetch from "../../hooks/useFetch";
import { formatCurrency } from "../../utils/helpers";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../utils/constants";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useQuery } from "@tanstack/react-query";
import { getAccountInfo } from "../../api";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker-cssmodules.css";

export default function WalletScreen() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const { isLoading, isError, data } = useQuery({
    queryFn: getAccountInfo,
    queryKey: ["getAccountInfo"],
  });
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className="py-6 p-md-6 p-lg-10">
      <h2 className="mb-6">Хэтэвч</h2>
      <div className="col-lg-9 col-md-8 col-12">
        <div
          className="d-flex flex-direction-row"
          style={{ flexWrap: isMobile ? "wrap" : "nowrap" }}
        >
          <div
            className="p-6 me-6 col-12 col-md-6"
            style={{
              height: "10rem",
              borderRadius: 12,
              backgroundColor: SECONDARY_COLOR,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="row">
              <div className="d-flex justify-content-between">
                <div className="text-white">Миний хэтэвч</div>
                <div className="btn btn-primary">Цэнэглэх</div>
              </div>
            </div>
            <div className="text-white fw-bold">
              {formatCurrency(data?.Balance)}
            </div>

            <div
              style={{
                border: "none",
                borderTop: "1px dashed #fff",
                color: "#fff",
                height: "2px",
              }}
            />
            <div className="row">
              <div
                className="d-flex justify-content-between"
                style={{ width: "100%" }}
              >
                <div>Хүлээгдэж буй төлбөр</div>
                <div>{formatCurrency(data?.PaymWaitAmount)}</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-6 mt-md-0">
            <div className="card py-3 px-6">
              <div className="mb-3">Данснаасаа мөнгө гаргах</div>
              <form>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Дүн"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append border-left-0">
                    <span className="input-group-text" id="basic-addon2">
                      ₮
                    </span>
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={(e) => {}}
                >
                  Хүсэлт илгээх
                </button>
              </form>
            </div>
          </div>
        </div>
        <div
          className="d-flex mt-6"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div className="form-group me-3" style={{ width: "33%" }}>
            <input defaultValue={startDate} />
            <label className="form-label">Эхлэх огноо</label>
            <select
              className="form-select"
              value={"2025.05.14"}
              defaultValue={"120"}
            ></select>
          </div>
          <div className="form-group ms-3" style={{ width: "33%" }}>
            <label className="form-label">Дуусах огноо</label>
            <select className="form-select"></select>
          </div>
          <div>
            <div className="btn btn-primary">Хуулга үзэх</div>
          </div>
        </div>

        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
      </div>
    </div>
  );
}
