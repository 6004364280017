import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { capitalizeFirstLetter, formatCurrency } from "../../utils/helpers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUserOrderDetails } from "../../api";
import { PRIMARY_COLOR } from "../../utils/constants";
import OrderDetailsModalShimmer from "./order-details-shimmer";
import Modal from "react-bootstrap/Modal";

export default function OrderDetailsModal({ showModal, closeModal }) {
  const [searchParams] = useSearchParams();
  const i = searchParams.get("i") || "";
  const { isPending, mutate, data } = useMutation({
    mutationFn: (i) => getUserOrderDetails(i),
    mutationKey: ["getUserOrderDetails", i],
  });
  useEffect(() => {
    mutate(i);
  }, [i]);

  return (
    <Modal className="modal fade modal-xl" show={showModal}>
      <div className="modal-content px-0 py-4 px-md-4">
        <Modal.Header className="border-0">
          <h5 className="modal-title fs-3 fw-bold" id="orderDetailsModalLabel">
            Миний захиалга
          </h5>
          <button type="button" className="btn-close" onClick={closeModal} />
        </Modal.Header>
        <div className="modal-body">
          {isPending ? (
            <OrderDetailsModalShimmer />
          ) : (
            <>
              <div className="card p-6">
                <div className="fw-bold text-primary text-center fs-5">
                  {data?.SalesOrderInfo?.StatusName}
                </div>
              </div>
              <div className="card p-6 mt-3">
                <div className="d-flex justify-content-between">
                  <small className="text-secondary">Захиалгын дугаар</small>
                  <small className="text-secondary">
                    Захиалга хийсэн огноо
                  </small>
                </div>
                <div className="d-flex justify-content-between mt-1">
                  <div className="fw-bold"> {data?.SalesOrderInfo?.Id}</div>
                  <div className="fw-bold">
                    {data?.SalesOrderInfo?.CreatedDateTime}
                  </div>
                </div>
                <hr
                  style={{
                    border: "none",
                    borderTop: "2px dotted #e6e6e6",
                    color: "#fff",
                    backgroundColor: "#fff",
                    height: "1px",
                  }}
                />
                <div className="d-flex justify-content-between mt-1">
                  <div className="">Барааны дүн</div>
                  <div className="fw-bold">
                    {formatCurrency(data?.SalesOrderInfo?.GoodsAmount)}
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-1">
                  <div className="">Хүргэлтийн төлбөр</div>
                  <div className="fw-bold">
                    {formatCurrency(data?.SalesOrderInfo?.DeliveryAmount)}
                  </div>
                </div>
                <hr
                  style={{
                    border: "none",
                    borderTop: "2px dotted #e6e6e6",
                    color: "#fff",
                    backgroundColor: "#fff",
                    height: "1px",
                  }}
                />
                <div className="d-flex justify-content-between mt-1">
                  <div className="">Нийт дүн</div>
                  <div className="fw-bold">
                    {formatCurrency(data?.SalesOrderInfo?.TotalAmount)}
                  </div>
                </div>
              </div>
              <div className="card p-6 mt-3">
                <div className="fw-bold mb-6">Барааны жагсаалт</div>
                {data?.SalesLinesList?.map((e, i) => (
                  <div className="mt-3">
                    <div className="row">
                      <div
                        className="col-2 rounded"
                        style={{
                          padding: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className="rounded"
                          src={e.ItemImageURL}
                          alt="Ecommerce"
                          style={{
                            maxWidth: "6rem",
                            maxHeight: "6rem",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>

                      {/* input group */}
                      <div
                        className="col px-0 ps-3 px-md-3"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div className="row">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "start",
                            }}
                          >
                            <div className="text-inherit">
                              <h6
                                className="mb-0"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  lineHeight: "20px",
                                  maxHeight: "100px",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {capitalizeFirstLetter(e.BriefDescrTrans)}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="border rounded p-1">
                            {e.ConfigText}
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="fw-bold">
                              {formatCurrency(e.AmountCust)}
                            </span>
                            <div className="border rounded py-1 px-2 ms-3">
                              <i class="bi bi-x"></i>
                              {e.Qty}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="card p-6 mt-3">
                <div className="fw-bold mb-6">Хүргэлтийн мэдээлэл</div>
                <div className="d-flex justify-content-between">
                  <div className="">Хүргэлтийн төрөл</div>
                  <div className="fw-bold">
                    {data?.SalesOrderInfo?.DeliveryModeName}
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-1">
                  <div className="">Хүргэлтийн хаяг</div>
                  <div className="fw-bold">
                    {`${data?.SalesOrderInfo?.DeliveryAddress.Country}, ${data?.SalesOrderInfo?.DeliveryAddress.City}, ${data?.SalesOrderInfo?.DeliveryAddress.Address}`}
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-1">
                  <div className="">Утас</div>
                  <div className="fw-bold">
                    {data?.SalesOrderInfo?.DeliveryAddress?.Phone}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="mt-8 d-flex justify-content-end">
          <button className="btn border" type="button" onClick={closeModal}>
            Хаах
          </button>
          {data?.SalesOrderInfo?.StatusCode === "10" && (
            <button
              className="btn border ms-3 btn-primary"
              type="button"
              onClick={closeModal}
            >
              Төлбөр төлөх
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}
