export default function AsuultScreen() {
  return (
    <div className="container">
      <div className="row">
        <div className="col col-md-8 m-auto">
          <h4 className="my-6 mt-8 text-center">Захиалга хэрхэн хийх вэ?</h4>
          <div className="mb-6" style={{ textAlign: "justify" }}>
            Та бүртгүүлэх хэсгээр QMALL-д тавтай морилно уу! "БҮРТГҮҮЛЭХ ЗААВАР"
            -ын дагуу захиалга хийхэд шаардлагатай мэдээллүүдээ бидэнд өгснөөр
            захиалга үүсгэх боломжтой болох юм. Та сонирхсон бараагаа
            АНГИЛАЛ-аар нь, эсвэл бидний зүгээс таньд САНАЛ БОЛГОЖ буй
            бүтээгдэхүүн, эсвэл цахим дэлгүүрээс санал болгож буй ЭРЭЛТТЭЙ
            бараануудаас болон манай цахим дэлгүүрээр үйлчлүүлэгч нарын СҮҮЛД
            ҮЗСЭН бараануудаас өөрийн дураар хайж, сонголтоо хийж болох ба
            баталгаажуулж, захиалга үүсгэхдээ дараах үйлдлүүдийг хийнэ. 1.
            Сонгосон бүтээгдэхүүн дээрээ дарж ороод, барааныхаа ХЭМЖЭЭ-г сонгож,
            ӨНГӨӨР ЭРЭМБЭЛСЭН хэсгээс өнгөө сонгосноор захиалга хийх товчлуур
            идэвхэжнэ. Мөн сонгосон барааны хэсэг дээр гарах мэдээллийг дээш,
            доош гүйлгэн тухайн барааны бусад зургууд, үзүүлэлтүүд, ижил төстэй
            бараанууд болон зүүн гар талд байрлах ДЭЛГҮҮРИЙН МЭДЭЭЛЭЛ,
            ДЭЛГҮҮРИЙН БУСАД БАРАА зэрэг сонголтуудаас тухайн барааг борлуулж
            буй дэлгүүр, барааны талаар дэлгэрүүлэн мэдээлэл авах боломжтой.
          </div>
          <img
            src="assets/images/jpg/asuult1.jpg"
            style={{ width: "100%" }}
            className="mb-6"
          />
          <div className="mb-6" style={{ textAlign: "justify" }}>
            2. ШУУД ЗАХИАЛАХ эсвэл САГСАНД НЭМЭХ товч дээр дарж захиалгаа
            үргэлжлүүлнэ. Мөн захиалах товчлуурын дор байрлах "Зүрх"-ний дүрс
            дээр дарж өөрийн ТААЛАГДСАН БАРАА-ны жагсаалтдаа нэмж болох ба дараа
            нь дахин олоход хялбар болно. Захиалах гэж буй бараагаа өөрийн
            САГСАНД нэмсэн бол бусад бараануудаа сонгож дууссаны дараа "ТАНЫ
            САГС" руу орох баруун дээд хэсэгт байрлах дээр дарж захиалгаруугаа
            орно. "ТАНЫ САГС" хэсэгт та тухайн барааныхаа сонгосон ӨНГӨ,
            ЗАГВАРЫГ өөрчлөх, ТОО ХЭМЖЭЭГ нэмэх, хасах боломжтой ба баруун гар
            талд байрлах "ЗАХИАЛГА ХИЙХ" товчийг дарж "ХҮРГЭЛТИЙН МЭДЭЭЛЭЛ"
            хэсэг рүү орно.
          </div>
          <img
            src="assets/images/jpg/asuult2.jpg"
            style={{ width: "100%" }}
            className="mb-6"
          />
          <div className="mb-6" style={{ textAlign: "justify" }}>
            3. "ХҮРГЭЛТИЙН МЭДЭЭЛЭЛ" хэсэгт бараа хүлээн авах хүний нэр,
            хүргүүлэх хаяг, холбоо барих утасны дугаар болон хүргэлтийн талаар
            бидэнд хэлэх, сануулах зүйлээ оруулж, баруун гар талд байрлах
            "БАТАЛГААЖУУЛАХ" товчийг дарж төлбөрийн хэсэг рүү шилжинэ.
          </div>
          <img
            src="assets/images/jpg/asuult3.jpg"
            style={{ width: "100%" }}
            className="mb-6"
          />
          <div className="mb-6" style={{ textAlign: "justify" }}>
            4. Төлбөрийн хэсэгт шилжихэд таны захиалгын дугаар "0000000***"
            байдалтай үүссэн байх ба энэ хэсэгт таны захиалгын нийт дүнг
            харуулна. Баруун гар талд байрлах "ТӨЛБӨР ТӨЛӨХ" товчийг дарж
            худалдан авалтаа хийнэ. Тайлбар: БАРААНЫ ҮНЭ + ХЯТАД УЛС ДОТОРХ
            ТЭЭВРИЙН ХӨЛС = ТАНЫ ТӨЛӨХ ДҮН байна. Ихэнх тохиолдолд ХЯТАД УЛС
            ДОТОРХ ТЭЭВРИЙН ХӨЛС нь үнэгүй байдаг тул зарим үед нийт үнэ нь
            зөвхөн барааны үнэ гардаг.
          </div>
          <img
            src="assets/images/jpg/asuult4.jpg"
            style={{ width: "100%" }}
            className="mb-6"
          />
          <div className="mb-6" style={{ textAlign: "justify" }}>
            5. Төлбөр төлөгдсөнөөс хойш 5-10 минутын дараа автоматаар төлбөр
            баталгаажих бөгөөд бараа захиалагдсан болон илгээгдсэн зэрэг төлвийг
            "ЗАХИАЛСАН БАРААГАА ХАРАХ" хэсгээс шалгах боломжтой. Захиалгын
            төлбөр хийх зааврыг "ТӨЛБӨР ТӨЛӨХ ЗААВАР" хуудсанаас харна уу.
          </div>
          <img
            src="assets/images/jpg/asuult5.jpg"
            style={{ width: "100%" }}
            className="mb-14"
          />
        </div>
      </div>
    </div>
  );
}
