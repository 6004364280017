import { SHIMMER_COLOR } from "../../utils/constants";

export default function OrderDetailsModalShimmer() {
  const shimmerStyle = {
    width: "100%",
    height: 12,
    borderRadius: 25,
    backgroundColor: SHIMMER_COLOR,
    margin: "8px",
  };
  return (
    <div>
      <div className="card p-6">
        <div
          className="fw-bold text-primary text-center"
          style={{
            ...shimmerStyle,
            height: 24,
            width: "30%",
            alignSelf: "center",
          }}
        />
      </div>
      <div className="card p-6 mt-3">
        <div className="d-flex justify-content-between">
          <div style={{ ...shimmerStyle, width: "40%" }}></div>
          <div style={{ ...shimmerStyle, width: "40%" }}></div>
        </div>
        <div className="d-flex justify-content-between">
          <div style={{ ...shimmerStyle, width: "40%" }}></div>
          <div style={{ ...shimmerStyle, width: "40%" }}></div>
        </div>
        <div className="d-flex justify-content-between">
          <div style={{ ...shimmerStyle, width: "40%" }}></div>
          <div style={{ ...shimmerStyle, width: "40%" }}></div>
        </div>
      </div>

      <div className="card p-6 mt-3">
        {[...Array(2)].map((e, i) => (
          <div className="d-flex justify-content-between">
            <div
              style={{
                ...shimmerStyle,
                width: "6rem",
                height: "6rem",
                borderRadius: 8,
              }}
            />
            <div
              style={{
                width: "85%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <div style={{ ...shimmerStyle, width: "90%" }}></div>
              <div style={{ ...shimmerStyle, width: "80%" }}></div>
              <div style={{ ...shimmerStyle, width: "70%" }}></div>
              {/* <div style={{ ...shimmerStyle, width: "50%" }}></div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
