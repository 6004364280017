import { useState } from "react";
import { formatCurrency } from "../../utils/helpers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addOrder, createOrder, createUserProfileInfo } from "../../api";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

export default function CartSideBar({ userProfile, comment, text }) {
  const navigate = useNavigate();
  const { state } = useLocation();

  // let orderData = {
  //   DeliveryModeId: userProfile?.ExternalDeliveryId,
  //   UserProfileId: userProfile?.Id?.Value,
  //   Comment: `${text}, ` + comment.toString(),
  //   Products: state.data.Products,
  // };

  const Item = state.data.Products.map((e) => {
    const obj = {
      Id: e.Id,
      ConfigurationId: e.ConfigurationId,
      Quantity: e.Quantity || 1,
      Weight: e.Weight || 0,
      Comment: e.Comment || "",
      PriceType: e.PriceType || "Default",
    };
    return obj;
  });

  let orderData = {
    OrderAddData: {
      UserProfileId: userProfile?.Id?.Value,
      DeliveryModeId: userProfile?.ExternalDeliveryId,
      Comment: "Need attention",
      Items: { Item },
    },
  };

  const { isPending: addingOrder, mutate: addNewOrder } = useMutation({
    mutationFn: (body) => addOrder(body),
    mutationKey: ["addOrder"],
    onSuccess: (data) => {
      if (data.success) {
        navigate(`/profile/orders?i=${data.Id}&m=true`);
      } else {
        toast.error("Алдаа гарлаа");
      }
    },
    onError: (err) => {
      toast.error(err);
    },
  });

  const { isPending: creatingOrder, mutate: createNewOrder } = useMutation({
    mutationFn: (body) => createOrder(body),
    mutationKey: ["createOrder"],
    onSuccess: (data) => {
      if (data.success) {
        navigate(`/profile/orders?i=${data.data.Id}&m=true`);
      } else {
        toast.error("Алдаа гарлаа");
      }
      // navigate("/profile/orders");
    },
    onError: (err) => {
      toast.error(err);
    },
  });

  const { isPending: creatingProfile, mutate: createNewprofile } = useMutation({
    mutationFn: () => createUserProfileInfo(orderData),
    mutationKey: ["createUserProfileInfo"],
    onSuccess: (data) => {
      if (data.ErrorCode === "Ok") {
        orderData.UserProfileId = data.Result.Value;
        if (state.fromCart) {
          createNewOrder(orderData);
        } else {
          addNewOrder(orderData);
        }
      } else {
        toast.error(data.ErrorMessage || "Алдаа гарлаа");
      }
    },
  });

  const onSubmit = () => {
    if (
      !userProfile.LastName ||
      !userProfile.FirstName ||
      !userProfile.City ||
      !userProfile.Address ||
      !userProfile.Phone
    ) {
      toast.error("Хүргэлтийн мэдээлэл дутуу байна");
      return;
    }
    if (!userProfile.ExternalDeliveryId) {
      toast.error("Хүргэлтийн төрлөө сонгоно уу");
      return;
    }
    if (!userProfile.Id.Value) {
      createNewprofile(orderData);
    } else {
      addNewOrder(orderData);
      console.log(orderData, "ORDERDATA=======");
      // if (state.fromCart) {
      //   createNewOrder(orderData);
      // } else {
      //   addNewOrder(orderData);
      // }
    }

    // if (state.fromCart) {
    //   createNewOrder(temp);
    // } else {
    //   temp.Products[0].TotalPrice = parseInt(state.data.TotalPrice);
    //   addNewOrder(temp);
    // }
  };

  return (
    <div className="card-body">
      {/* heading */}
      <div className="card mb-2">
        {/* list group */}
        <ul className="list-group list-group-flush">
          {/* list group item */}

          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="me-auto">
              <div>Нийт бараа</div>
            </div>
            <span className="text-secondary">{state.data.Products.length}</span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="me-auto">
              <div>Барааны үнэ</div>
            </div>
            <span>{formatCurrency(state.data.TotalPrice)}</span>
          </li>
          {/* list group item */}
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="me-auto">
              <div>Хятад доторх хүргэлт</div>
            </div>
            <span>{formatCurrency(state.data.DeliveryPrice)}</span>
          </li>
          {/* list group item */}
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="me-auto">
              <div className="fw-bold">Төлөх дүн</div>
            </div>
            <span className="fw-bold">
              {formatCurrency(state.data.TotalAmount)}
            </span>
          </li>
        </ul>
      </div>
      <div className="alert alert-warning mt-6" role="alert">
        Тээврийн зардал бараа ирсэний дараа нэмэлтээр бодогдож төлөгдөхийг
        анхаарна уу
      </div>
      <div className="d-grid mb-1 mt-4">
        {addingOrder || creatingOrder ? (
          <button className="btn btn-primary" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></span>
            Түр хүлээнэ үү...
          </button>
        ) : (
          <button
            className={`btn btn-primary btn-lg`}
            type="submit"
            loading={false}
            disabled={state.data.TotalAmount == 0}
            // data-bs-toggle={"modal"}
            // data-bs-target={"#paymentModal"}
            onClick={onSubmit}
          >
            Баталгаажуулах
          </button>
        )}
      </div>
    </div>
  );
}
