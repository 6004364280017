import { SHIMMER_COLOR } from "../../utils/constants";

export default function ProductShimmer({ count, cols }) {
  const style = {
    backgroundColor: SHIMMER_COLOR,
    height: 12,
    width: "100%",
    borderRadius: 25,
  };
  return (
    <div
      className={`row g-4 row-cols-xl-${cols} row-cols-lg-${
        cols - 1
      } row-cols-2 row-cols-md-2 mt-3`}
    >
      {[...Array(count)].map((e, i) => (
        <div className="col" key={i}>
          <div className="card card-product">
            <div className="card-body p-0">
              <div className="text-center position-relative">
                <img
                  src="../../../assets/images/jpg/no-image.jpg"
                  alt={"no-image"}
                  className="mb-3 img-fluid rounded"
                />
              </div>
              <div className="p-3">
                <div className="col-12 mb-3" style={style}></div>
                <div className="col-12" style={style}></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
