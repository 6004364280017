import { Tabs, Tab } from "react-tabs-scrollable";
import "./style.css";
import useFetch from "../../hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { changeCatName } from "../../utils/helpers";

export default function CategoryTabs() {
  let { cat1, cat2 } = useParams();
  const { data, loading, error } = useFetch(
    `/getSubCategoryInfo?parentCategoryId=otc-${cat2}`
  );
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const onTabClick = (e, i) => {
    setIndex(i);
    const item = data.Content[i];
    navigate(`/category/${cat1}/${cat2}/${changeCatName(item.Id)}`, {
      replace: true,
    });
  };
  useEffect(() => {
    if (!loading) {
      if (data.Content.length > 0) {
        const item = data.Content[0];
        navigate(`/category/${cat1}/${cat2}/${changeCatName(item.Id)}`, {
          replace: true,
        });
      } else {
        navigate(`/category/${cat1}/${cat2}/${cat2}`, {
          replace: true,
        });
      }
    }
  }, [loading]);
  const shimmerStyle = {
    backgroundColor: "#ecf0ef",
    height: 42,
    width: "100%",
    borderRadius: 50,
  };

  return (
    <div className="mb-6 mt-0">
      {loading ? (
        <div className="d-flex mb-6">
          {[...Array(4)].map((item) => (
            <div style={shimmerStyle} className="me-3" />
          ))}
        </div>
      ) : (
        <Tabs
          tabsContainerClassName="primary"
          tabsUpperContainerClassName="primary"
          leftNavBtnClassName="primary"
          rightNavBtnClassName="primary"
          navBtnClassName="primary"
          navBtnContainerClassName="primary"
          activeTab={index}
          tabsScrollAmount={3}
          showTabsScroll={false}
          mode="scrollSelectedToCenterFromView"
          onTabClick={onTabClick}
          tabsContainerStyle={{ padding: 0 }}
          tabsUpperContainerStyle={{ padding: 0 }}
        >
          {data?.Content?.map((e, i) => (
            <Tab>{e.Name}</Tab>
          ))}
        </Tabs>
      )}
    </div>
  );
}
