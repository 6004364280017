import { useEffect, useState } from "react";

function useAuthentication() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkLogin = () => {
    const token = localStorage.getItem("token");
    const newVal = token && token !== "";
    setIsLoggedIn(newVal);
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return { isLoggedIn };
}
export default useAuthentication;

// Usage
// const { isLoggedIn } = useAuthentication();
