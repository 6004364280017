export const filters = [
  {
    title: "Энгийн",
    value: "",
  },
  {
    title: "Үнэ өсөлтөөр",
    value: "Price:asc",
  },
  {
    title: "Үнэ бууралтаар",
    value: "Price:desc",
  },
  {
    title: "Нийт үнэ өсөх дарааллаар",
    value: "TotalPrice:asc",
  },
  {
    title: "Нийт үнэ буурах дарааллаар",
    value: "TotalPrice:desc",
  },
  {
    title: "Их зарагдсан",
    value: "Volume:desc",
  },
  {
    title: "Дэлгүүрийн зэрэглэлээр",
    value: "VendorRating:desc",
  },
  {
    title: "Шинэчлэгдсэн бараа",
    value: "UpdatedTime:desc",
  },
];
