import { useState } from "react";
import { signUp, socialAuth } from "../../api";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

export default function SignUp() {
  const [passwordShow, setPasswordShow] = useState(false);
  const [isCheckedAgreement, setCheckedAgreement] = useState(false);
  const [signUpInfo, setSignUpInfo] = useState({
    Email: "",
    Login: "",
    Password: "",
  });
  const [socialType, setSocialType] = useState("");
  const onEmailChange = (e) => {
    setSignUpInfo((prevState) => ({ ...prevState, Email: e.target.value }));
  };
  const onLoginChange = (e) => {
    setSignUpInfo((prevState) => ({
      ...prevState,
      Login: e.target.value,
    }));
  };
  const onPasswordChange = (e) => {
    setSignUpInfo((prevState) => ({
      ...prevState,
      Password: e.target.value,
    }));
  };

  const { mutate: signUpUser, isPending } = useMutation({
    mutationFn: () => signUp(signUpInfo),
    mutationKey: ["signUp"],
    onSuccess: (data) => {
      const { token, ErrorDescription } = data;
      if (token) {
        localStorage.setItem("token", token);
        window.location.reload();
      } else {
        toast.error(ErrorDescription || "Алдаа гарлаа");
      }
    },
  });
  const {
    mutate: socialLogin,
    isPending: isSocialPending,
    data,
  } = useMutation({
    mutationFn: () => socialAuth(socialType),
    mutationKey: ["socialAuth"],
    onSuccess: (data) => {
      window.location.replace(data?.RedirectUrl);
    },
  });

  return (
    <div
      class="modal fade"
      id="signUpModal"
      aria-hidden="true"
      aria-labelledby="signUpModalLabel"
      tabindex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-4">
          <div className="modal-header border-0">
            <h5 className="modal-title fs-3 fw-bold" id="signUpModalLabel">
              Бүртгүүлэх
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              className="mb-8"
            >
              <img src="/assets/images/svg/logo.svg" />
            </div>
            <form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  placeholder="Нэвтрэх нэр"
                  required
                  onChange={onLoginChange}
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Имэйл хаяг"
                  required
                  onChange={onEmailChange}
                />
              </div>
              <div className="mb-5">
                <div class="input-group">
                  <input
                    id="loginPassword"
                    type={passwordShow ? "text" : "password"}
                    className="form-control rounded"
                    placeholder="Нууц үг"
                    required
                    onChange={onPasswordChange}
                  />
                  <div
                    className="input-group-addon"
                    onClick={() => setPasswordShow(!passwordShow)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className={`bi ${
                        !passwordShow ? "bi-eye" : "bi-eye-slash"
                      } me-3`}
                      id="togglePassword"
                    ></i>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <small className="text-secondary mt-3">
                    *Хамгийн багадаа 6 тэмдэгт оруулна
                  </small>
                </div>
              </div>
              <div className="mb-6 ms-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue
                    id="defaultCheck1"
                    value={isCheckedAgreement}
                    onChange={() => setCheckedAgreement(!isCheckedAgreement)}
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Би
                    <a href="javascript:void(0)"> Үйлчилгээний нөхцөлийг </a>
                    зөвшөөрч байна
                  </label>
                </div>
              </div>

              {!isPending ? (
                <button
                  type="submit"
                  style={{ width: "100%" }}
                  className="btn btn-primary loading"
                  onClick={(e) => {
                    e.preventDefault();
                    if (signUpInfo.Email == "") {
                      toast.error(
                        "Хэрэглэгчийн имэйл хаяг оруулах шаардлагатай"
                      );
                    } else if (signUpInfo.Login == "") {
                      toast.error("Хэрэглэгчийн нэвтрэх нэр шаардлагатай");
                    } else if (signUpInfo.Password == "") {
                      toast.error("Хэрэглэгчийн нууц үг шаардлагатай");
                    } else if (!isCheckedAgreement) {
                      toast.error("Үйлчилгээний нөхцөл зөвшөөрөх шаардлагатай");
                    } else {
                      signUpUser();
                    }
                  }}
                >
                  Бүртгүүлэх
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  type="button"
                  disabled
                  style={{ width: "100%" }}
                >
                  <span
                    className="spinner-border spinner-border-sm me-3"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Түр хүлээнэ үү...
                </button>
              )}
            </form>
            <p
              className="my-8"
              style={{
                width: "100%",
                textAlign: "center",
                borderBottom: "1px solid #dfe2e1",
                lineHeight: "0.1em",
                margin: "10px 0 20px",
              }}
            >
              <span
                style={{
                  background: "#fff",
                  padding: "0 10px",
                }}
              >
                <small>Эсвэл</small>
              </span>
            </p>
            <div className="container my-3">
              <div className="row">
                {isSocialPending && socialType == "Facebook" ? (
                  <button className="btn rounded border" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm me-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Түр хүлээнэ үү...
                  </button>
                ) : (
                  <button
                    className="btn rounded border"
                    onClick={() => {
                      setSocialType("Facebook");
                      socialLogin();
                    }}
                  >
                    <img
                      className="me-3"
                      src="https://cdn-icons-png.flaticon.com/64/145/145802.png"
                      style={{ width: 16, height: 16 }}
                    ></img>
                    Facebook
                  </button>
                )}
              </div>
              <div className="row mt-3">
                {isSocialPending && socialType == "Google" ? (
                  <button className="btn rounded border" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm me-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Түр хүлээнэ үү...
                  </button>
                ) : (
                  <button
                    className="btn rounded border"
                    onClick={() => {
                      setSocialType("Google");
                      socialLogin();
                    }}
                  >
                    <img
                      className="me-3"
                      src="https://cdn-icons-png.flaticon.com/64/2702/2702602.png"
                      style={{ width: 16, height: 16 }}
                    ></img>
                    Gmail
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer border-0 justify-content-center">
            Та бүртгэлтэй юу?
            <a href="#" data-bs-toggle="modal" data-bs-target="#signInModal">
              Нэвтрэх
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
