import { useNavigate, useParams } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthentication";

export default function ProductController({
  qty,
  changeQty,
  configurationId,
  addToCart,
  addingToCart,
  data,
  totalPrice,
}) {
  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();

  const quickOrder = () => {
    const arr = {
      TotalPrice: totalPrice,
      DeliveryPrice: 0,
      TotalAmount: totalPrice,
      Products: [
        {
          Id: data.Id,
          ConfigurationId: configurationId,
          Quantity: qty,
          Weight: data?.Weight?.Value || 0,
        },
      ],
    };
    navigate("/checkout", {
      state: {
        data: arr,
        fromCart: false,
      },
    });
  };

  return (
    <div>
      <div className="my-6">
        {/* input */}
        <div className="input-group input-spinner">
          <input
            type="button"
            defaultValue="-"
            className="button-minus btn btn-sm"
            data-field="quantity"
            onClick={() => changeQty(false)}
            disabled={qty == 1}
          />
          <input
            type="number"
            step={1}
            max={10}
            value={qty}
            name="quantity"
            className="quantity-field form-control-sm form-input"
          />
          <input
            type="button"
            defaultValue="+"
            className="button-plus btn btn-sm"
            data-field="quantity"
            onClick={() => changeQty(true)}
          />
        </div>
      </div>

      <div className="row justify-content-start g-2 align-items-center">
        <div className="col-xxl-4 col-lg-4 col-md-5 col-5 d-grid">
          <button
            data-bs-toggle={isLoggedIn ? "" : "modal"}
            data-bs-target={isLoggedIn ? "" : "#signInModal"}
            type="button"
            className="btn btn-primary"
            onClick={isLoggedIn && quickOrder}
            disabled={configurationId == 0}
          >
            Шууд захиалах
          </button>
        </div>
        <div className="col-xxl-4 col-lg-4 col-md-5 col-5 d-grid">
          {addingToCart ? (
            <button
              className="btn btn-outline-secondary"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></span>
              Түр хүлээнэ үү...
            </button>
          ) : (
            <button
              onClick={isLoggedIn && addToCart}
              data-bs-toggle={isLoggedIn ? "" : "modal"}
              data-bs-target={isLoggedIn ? "" : "#signInModal"}
              className="btn btn-outline-secondary"
              disabled={configurationId == 0}
            >
              Сагсанд нэмэх
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
