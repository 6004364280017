import { LuBed, LuHome, LuShirt } from "react-icons/lu";

import { LuShoppingBag } from "react-icons/lu";
import { TbPerfume } from "react-icons/tb";
import { TbShoe } from "react-icons/tb";
import { LuBaby } from "react-icons/lu";
import { TbBabyBottle } from "react-icons/tb";
import { MdOutlineSportsBaseball } from "react-icons/md";
import { LuSailboat } from "react-icons/lu";
import { LuComputer } from "react-icons/lu";
import { LuHeart } from "react-icons/lu";
import { LuBedSingle } from "react-icons/lu";

export default function Icons({ id }) {
  if (id == "otc-1")
    return (
      <LuShirt
        width={24}
        height={24}
        color="#3d4f58"
        style={{ pointerEvents: "none" }}
      />
    );
  else if (id == "otc-6025")
    return <LuBed width={24} height={24} color="#3d4f58" />;
  else if (id == "otc-273")
    return <LuShoppingBag width={24} height={24} color="#3d4f58" />;
  else if (id == "otc-284")
    return <TbPerfume width={24} height={24} color="#3d4f58" />;
  else if (id == "otc-393")
    return <LuBaby width={24} height={24} color="#3d4f58" />;
  else if (id == "otc-906")
    return <TbBabyBottle width={24} height={24} color="#3d4f58" />;
  else if (id == "otc-1174")
    return <MdOutlineSportsBaseball width={24} height={24} color="#3d4f58" />;
  else if (id == "otc-2088")
    return <LuSailboat width={24} height={24} color="#3d4f58" />;
  else if (id == "otc-2451")
    return <LuComputer width={24} height={24} color="#3d4f58" />;
  else if (id == "otc-2717")
    return <LuHeart width={24} height={24} color="#3d4f58" />;
  else if (id == "otc-212")
    return <TbShoe width={24} height={24} color="#3d4f58" />;
  else return <LuShirt width={24} height={24} color="#3d4f58" />;
}
