import { useState } from "react";
import SignUp from "./signup";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { signIn, socialAuth } from "../../api";
import PasswordRecoveryModal from "./passRecovery";

export default function SignIn() {
  const [passwordShow, setPasswordShow] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    userLogin: "",
    userPassword: "",
    rememberMe: false,
  });
  const [loading, setLoading] = useState(false);
  const [socialType, setSocialType] = useState("");
  const loginChange = (e) => {
    setLoginInfo((prevState) => ({ ...prevState, userLogin: e.target.value }));
  };
  const passwordChange = (e) => {
    setLoginInfo((prevState) => ({
      ...prevState,
      userPassword: e.target.value,
    }));
  };
  const { mutate: signInUser, isPending } = useMutation({
    mutationFn: () => signIn(loginInfo),
    mutationKey: ["signIn"],
    onSuccess: (data) => {
      const { token, ErrorDescription } = data;
      if (token) {
        localStorage.setItem("token", token);
        window.location.reload();
      } else {
        toast.error(ErrorDescription || "Алдаа гарлаа");
      }
    },
  });

  const {
    mutate: socialLogin,
    isPending: isSocialPending,
    data,
  } = useMutation({
    mutationFn: () => socialAuth(socialType),
    mutationKey: ["socialAuth"],
    onSuccess: (data) => {
      window.location.replace(data?.RedirectUrl);
    },
  });

  return (
    <>
      <div
        className="modal fade"
        id="signInModal"
        tabIndex={-1}
        aria-labelledby="signInModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-4">
            <div className="modal-header border-0">
              <h5 className="modal-title fs-3 fw-bold" id="signInModalLabel">
                Нэвтрэх
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="mb-8"
              >
                <img src="/assets/images/svg/logo.svg" />
              </div>

              <form>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    placeholder="Нэвтрэх нэр"
                    required
                    onChange={loginChange}
                  />
                </div>
                <div className="mb-5">
                  <div class="input-group">
                    <input
                      id="loginPassword"
                      type={passwordShow ? "text" : "password"}
                      className="form-control rounded"
                      placeholder="Нууц үг"
                      onChange={passwordChange}
                    />
                    <div
                      className="input-group-addon"
                      onClick={() => setPasswordShow(!passwordShow)}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className={`bi ${
                          !passwordShow ? "bi-eye" : "bi-eye-slash"
                        } me-3`}
                        id="togglePassword"
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-4 mx-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      id="flexCheckDefault"
                      value={loginInfo.rememberMe}
                      onChange={() =>
                        setLoginInfo((prevState) => ({
                          ...prevState,
                          rememberMe: !loginInfo.rememberMe,
                        }))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Сануулах
                    </label>
                  </div>
                  <div>
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#passRecoveryModal"
                      style={{ cursor: "pointer" }}
                    >
                      <small>Нууц үгээ мартсан уу?</small>
                    </a>
                  </div>
                </div>
                {!isPending ? (
                  <button
                    type="submit"
                    className="btn btn-primary loading"
                    style={{ width: "100%" }}
                    onClick={(e) => {
                      e.preventDefault();
                      signInUser();
                    }}
                  >
                    Нэвтрэх
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="button"
                    disabled
                    style={{ width: "100%" }}
                  >
                    <span
                      className="spinner-border spinner-border-sm me-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Түр хүлээнэ үү...
                  </button>
                )}
              </form>
              <p
                className="my-8"
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderBottom: "1px solid #dfe2e1",
                  lineHeight: "0.1em",
                  margin: "10px 0 20px",
                }}
              >
                <span
                  style={{
                    background: "#fff",
                    padding: "0 10px",
                  }}
                >
                  <small>Эсвэл</small>
                </span>
              </p>

              <div className="container my-3">
                <div className="row">
                  {isSocialPending && socialType == "Facebook" ? (
                    <button
                      className="btn rounded border"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm me-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Түр хүлээнэ үү...
                    </button>
                  ) : (
                    <button
                      className="btn rounded border"
                      onClick={() => {
                        setSocialType("Facebook");
                        socialLogin();
                      }}
                    >
                      <img
                        className="me-3"
                        src="https://cdn-icons-png.flaticon.com/64/145/145802.png"
                        style={{ width: 16, height: 16 }}
                      ></img>
                      Facebook
                    </button>
                  )}
                </div>
                <div className="row mt-3">
                  {isSocialPending && socialType == "Google" ? (
                    <button
                      className="btn rounded border"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm me-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Түр хүлээнэ үү...
                    </button>
                  ) : (
                    <button
                      className="btn rounded border"
                      onClick={() => {
                        setSocialType("Google");
                        socialLogin();
                      }}
                    >
                      <img
                        className="me-3"
                        src="https://cdn-icons-png.flaticon.com/64/2702/2702602.png"
                        style={{ width: 16, height: 16 }}
                      ></img>
                      Gmail
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              Та бүртгэлгүй юу?
              <a href="" data-bs-toggle="modal" data-bs-target="#signUpModal">
                Бүртгүүлэх
              </a>
            </div>
          </div>
        </div>
      </div>
      <SignUp />
      <PasswordRecoveryModal />
    </>
  );
}
