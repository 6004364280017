import axios from "axios";
import { getToken } from "../utils/helpers";
import { useQuery } from "@tanstack/react-query";

axios.defaults.headers.post["authorization"] = getToken();

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export default async function fetchData(route) {
  const { data } = await api.post(`/${route}`);
  return data.data;
}

export const fetchMainInfo = async (id) => {
  const { data } = await api.post(`getProductMainInfo?itemId=${id}`);
  return data?.data.Result;
};
export const fetchConfiguration = async (id, obj) => {
  const { data } = await api.post(`getConfigurations?itemId=${id}`, obj);
  return data?.data;
};

export const fetchDescriptions = async (id) => {
  const { data } = await api.post(`getProductDescriptions?itemId=${id}`);
  return data?.ItemDescription;
};

export const addItemToBasket = async (itemId, configurationId, quantity) => {
  const { data } = await api.post("addItemToBasket", {
    itemId,
    configurationId,
    quantity,
  });
  return data?.data;
};

export const getBasketList = async () => {
  const { data } = await api.post("getBasketList");
  return data?.data?.Basket?.Elements;
};

export const getCategoryList = async () => {
  const { data } = await api.post("getCategoryList");
  return data?.data;
};

export const getCategoryProducts = async (cat3, page, sort) => {
  const { data } = await api.post(
    `getProducts?cat=${cat3}&page=${page}&sort=${sort}`
  );
  return data?.data;
};

export const getVendorProducts = async (id, page, sort) => {
  const { data } = await api.post(
    `getProducts?vendorId=${id}&page=${page}&sort=${sort}`
  );
  return data?.data;
};

export const searchProducts = async (q, page, sort, image) => {
  const { data } = await api.post(
    `getProducts?title=${q}&page=${page}&sort=${sort}&image=${image}`
  );
  return data?.data;
};

export const signIn = async (loginInfo) => {
  const { data } = await api.post("authenticate", loginInfo);
  return data?.data;
};
export const recoverPassword = async (loginInfo) => {
  const { data } = await api.post(
    `recoverPassword?userIdentifier=${loginInfo}`
  );
  return data?.data;
};

export const signUp = async (signUpInfo) => {
  const { data } = await api.post("registerUser", signUpInfo);
  return data?.data;
};

export const getDeliveryModes = async () => {
  const { data } = await api.post("getDeliveryModes");
  return data?.data;
};

export const getUserProfileInfo = async () => {
  const { data } = await api.post("getUserProfileInfo");
  return data?.data;
};

export const createUserProfileInfo = async (body) => {
  const { data } = await api.post("createUserProfileInfo", body);
  return data?.data;
};

export const updateUserProfile = async (body) => {
  const { data } = await api.post("updateUserProfile", body);
  return data?.data;
};

export const deleteUserProfile = async (id) => {
  const { data } = await api.post(`deleteUserProfile?profileId=${id}`);
  return data?.data;
};

export const addOrder = async (body) => {
  const { data } = await api.post("addOrder", body);
  return data;
};
export const createOrder = async (body) => {
  const { data } = await api.post("createOrder", body);
  return data;
};

export const getUserOrders = async (page) => {
  const { data } = await api.post(`getUserOrders?page=${page}`);
  return data?.data;
};

export const getUserOrderDetails = async (id) => {
  const { data } = await api.post(`getOrderDetails?salesId=${id}`);
  return data?.data;
};

export const socialAuth = async (system) => {
  const { data } = await api.post(`socialAuth?authenticationSystem=${system}`);
  return data?.data;
};

export const getAccountInfo = async () => {
  const { data } = await api.post("getAccountInfo");
  return data?.data;
};
