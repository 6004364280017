import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetter,
  convertToInt,
  formatCurrency,
} from "../../utils/helpers";

export default function ProductCards({ data, cols }) {
  const navigate = useNavigate();
  return (
    <div
      className={`row g-4 row-cols-xl-${cols} row-cols-lg-${
        cols - 1
      } row-cols-2 row-cols-md-2 mt-2`}
    >
      {data.map((e, i) => (
        <div className="col" key={i}>
          <div className="card card-product">
            <div className="text-center position-relative mb-3 rounded">
              {/* <div className="position-absolute top-0 start-0 m-3">
              <span className="badge bg-danger">TMALL</span>
            </div> */}
              <div className="imageContainer">
                <img
                  className="card-img-top"
                  onClick={() => navigate(`/product/${e.Id}`, { state: e })}
                  src={e?.MainPictureUrl || "assets/images/jpg/no-image.jpg"}
                  alt={e?.Title || "image"}
                  loading="lazy"
                />
              </div>
            </div>
            <div className="card-body p-3">
              <h3 className="fs-5 card-title text-decoration-none text-truncate">
                {capitalizeFirstLetter(e?.Title || "Title")}
              </h3>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <span className="text-danger">
                  {formatCurrency(
                    convertToInt(e?.Price?.ConvertedPrice || "0")
                  )}
                </span>
                <span className="text-decoration-line-through text-muted">
                  {formatCurrency(
                    convertToInt(e?.Price?.ConvertedPrice || "0")
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
