import useFetch from "../../hooks/useFetch";

export default function InfoScreen() {
  const { data, loading, error } = useFetch("getUserInfo");

  return (
    <div className="col-lg-9 col-md-8 col-12">
      <div className="py-6 p-md-6 p-lg-10">
        <div className="mb-6">
          {/* heading */}
          <h2 className="mb-0">Профайл</h2>
        </div>
        <div>
          {/* heading */}
          <h5 className="mb-4">Хэрэглэгчийн мэдээлэл</h5>
          <div className="row">
            <div className="col-lg-5">
              {/* form */}
              <form>
                {/* input */}
                <div className="mb-3">
                  <label className="form-label">Нэвтрэх нэр</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Нэвтрэх нэр"
                    value={data?.UserInfo?.Login}
                    disabled
                  />
                </div>
                {/* input */}
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={data?.UserInfo?.Email}
                    disabled
                  />
                </div>
                {/* input */}
                <div className="mb-5">
                  <label className="form-label">Утас</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    disabled
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <hr className="my-10" />
      </div>
    </div>
  );
}
