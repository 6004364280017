import { useMutation, useQuery } from "@tanstack/react-query";
import { getUserProfileInfo, updateUserProfile } from "../../../api";
import { useEffect, useState } from "react";
import UserProfileAddressShimmer from "./shimmer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UserProfileAddressDeleteModal from "./delete-modal";
import UserProfileAddAddressModal from "./add-address-modal";
import UserInfoComponent from "./user-info-component";
import { toast } from "react-toastify";

export default function UserAddressScreen() {
  const [key, setKey] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [profile, setProfile] = useState({
    FirstName: "",
    LastName: "",
    Region: "",
    City: "",
    Address: "",
    Phone: "",
    ExternalDeliveryId: "",
    Id: "",
  });
  const { isLoading, isError, data } = useQuery({
    queryFn: () => getUserProfileInfo(),
    queryKey: ["getUserProfileInfo"],
  });

  useEffect(() => {
    data?.length > 0 && setUserProfile(data[0]);
  }, [data]);

  const setUserProfile = (e) => {
    const obj = {
      FirstName: e.FirstName,
      LastName: e.LastName,
      Region: e.Region,
      City: e.City,
      Address: e.Address,
      Phone: e.Phone,
      ExternalDeliveryId: e.ExternalDeliveryId,
      Id: e.Id.Value,
    };
    setProfile(obj);
  };

  const onSelect = (k) => {
    setKey(k);
    setUserProfile(data[k]);
  };

  const { isPending: updatingProfile, mutate: updateProfile } = useMutation({
    mutationFn: () => updateUserProfile(profile),
    mutationKey: ["updateUserProfile"],
    onSuccess: (data) => {
      if (data.ErrorCode === "Ok") {
        window.location.reload();
        toast.success("Амжилттай  хадгалагдлаа");
      } else {
        toast.error("Алдаа гарлаа");
      }
    },
  });

  return (
    <div className="py-6 p-md-6 p-lg-10">
      {/* heading */}
      <div className="row">
        <div className="d-flex justify-content-between">
          <h2>Хүргэлтийн хаягууд</h2>
          {!isLoading && data.length < 3 && (
            <button
              className="btn btn-outline-secondary"
              onClick={() => setShowAddModal(true)}
            >
              Хаяг нэмэх
            </button>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {isLoading ? (
            <UserProfileAddressShimmer />
          ) : (
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={onSelect}
              className="my-6"
            >
              {data?.map((el, i) => (
                <Tab eventKey={i} title={`Хаяг-${i + 1}`}>
                  <UserInfoComponent
                    profile={profile}
                    setProfile={setProfile}
                  />
                  <div className="my-3">
                    {updatingProfile ? (
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm me-3"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Түр хүлээнэ үү...
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-secondary"
                        onClick={updateProfile}
                      >
                        Хадгалах
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-primary ms-3"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      Устгах
                    </button>
                  </div>
                </Tab>
              ))}
            </Tabs>
          )}
        </div>
      </div>
      <UserProfileAddressDeleteModal
        show={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        id={profile.Id}
      />
      <UserProfileAddAddressModal
        show={showAddModal}
        hide={() => setShowAddModal(false)}
      />
    </div>
  );
}
