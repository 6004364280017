import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Icons from "../../mock/icons";
import { changeCatName } from "../../utils/helpers";

export default function MobileCategoryScreen() {
  const { data, loading, error } = useFetch("/getCategoryList");
  const navigate = useNavigate();

  return (
    <div id="sidebar">
      <div className="sidebar__inner">
        <div
          className="offcanvas offcanvas-start offcanvas-collapse"
          tabIndex={-1}
          id="offcanvasCategoryMobile"
          aria-labelledby="offcanvasCategoryMobileLabel"
        >
          <div className="offcanvas-header d-lg-none">
            <div className="text-start">
              <h5 id="offcanvasRightLabel" className="mb-0 fs-4">
                Ангилал
              </h5>
            </div>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body ps-lg-2 pt-0">
            <div className="mb-8">
              {/* nav */}
              <ul className="nav nav-category" id="categoryCollapseMenu">
                {data?.map((e, i) => (
                  <li className="nav-item border-bottom w-100 p-1" key={i}>
                    <a
                      href="#"
                      className="nav-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target={`#categoryFlush${i}`}
                      aria-expanded="false"
                      aria-controls={`categoryFlush${i}`}
                    >
                      <div className="d-flex align-items-center">
                        {<Icons id={e.Id} />}
                        <div className="ms-3 fs-6">{e.Name}</div>
                      </div>
                      <i className="feather-icon icon-chevron-right" />
                    </a>
                    {/* accordion collapse */}
                    <div
                      id={`categoryFlush${i}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#categoryCollapseMenu"
                    >
                      <div>
                        {/* nav */}
                        <ul className="nav flex-column ms-3">
                          {e?.Children?.map((k, n) => (
                            <a
                              href={`/category/${changeCatName(
                                e.Id
                              )}/${changeCatName(k.Id)}/0`}
                            >
                              <li className="nav-item active" key={n}>
                                <div
                                  className="nav-link"
                                  style={{ cursor: "pointer" }}
                                >
                                  {k.Name}
                                </div>
                              </li>
                            </a>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
