import useMediaQuery from "../../hooks/useMediaQuery";

export default function ProductDetailsShimmer() {
  // Usage
  const isMobile = useMediaQuery("(max-width: 768px)");
  const mainStyle = {
    backgroundColor: "#ecf0ef",
    height: "100%",
    width: "100%",
    borderRadius: 4,
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-5 col-xl-6 pt-4">
          {/* img slide */}
          <div
            style={{
              ...mainStyle,
              height: isMobile ? "18rem" : "30rem",
            }}
          ></div>
          {/* product tools */}
          <div className="product-tools">
            <div className="thumbnails row g-3" id="productThumbnails">
              {[...Array(6)].map((el, i) => (
                <div className="col">
                  <div
                    style={{ ...mainStyle, height: isMobile ? "3rem" : "5rem" }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Main information */}
        <div className="col-md-7 col-xl-6">
          {[...Array(isMobile ? 2 : 6)].map((el, i) => (
            <div>
              <div style={{ ...mainStyle, height: 32 }} className="my-6"></div>
              <div style={{ ...mainStyle, height: 16 }} className="my-6"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
