import Slider from "react-slick";

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span class="slick-prev" onClick={onClick}>
      <i class="feather-icon icon-chevron-left"></i>
    </span>
  );
}
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span class="slick-next" onClick={onClick}>
      <i class="feather-icon icon-chevron-right "></i>
    </span>
  );
}

var settings = {
  infinite: !0,
  slidesToShow: 8,
  slidesToScroll: 1,
  autoplay: !0,
  dots: !1,
  arrows: !0,
  speed: 500,
  loop: !0,
  adaptiveHeight: !0,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
      },
    },
  ],
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  // appendArrows: "#" + s + "-arrows",
};

const slides = [
  {
    title: "Хувцас",
    image: "1.jpg",
  },
  {
    title: "Гэр ахуй",
    image: "2.jpg",
  },
  {
    title: "Цүнх",
    image: "3.jpg",
  },
  {
    title: "Түрийвч",
    image: "4.jpg",
  },
  {
    title: "Эх нярай",
    image: "5.jpg",
  },
  {
    title: "Гутал",
    image: "6.jpg",
  },
  {
    title: "Хүүхэд",
    image: "7.jpg",
  },
  {
    title: "Хүүхэд",
    image: "1.jpg",
  },
];

export default function MenuSlider() {
  return (
    <section className="my-lg-8 my-8">
      <div className="container">
        <div className="row align-items-center mb-6">
          <div className="col-10">
            <div>
              {/* heading    */}
              <div className="col-12">
                <h4 className="mb-0"> Эрэлттэй ангиллууд</h4>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div
              className="slider-arrow slider-8-columns-arrow"
              id="slider-8-columns-arrows"
            />
          </div>
        </div>
        <div className="row g-6">
          <div className="col-12">
            <div className="position-relative">
              <Slider {...settings}>
                {slides.map((slide) => (
                  <div className="item">
                    <a
                      href="/category/1/8420/8565"
                      className="text-decoration-none text-inherit"
                    >
                      <div className="card border-0">
                        <img src={`assets/images/png/${slide.image}`} />
                      </div>
                    </a>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
