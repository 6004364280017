import { SHIMMER_COLOR } from "../../utils/constants";

export default function OrdersScreenShimmer() {
  const shimmerStyle = {
    width: "100%",
    height: 12,
    borderRadius: 25,
    backgroundColor: SHIMMER_COLOR,
    margin: "8px",
  };
  return (
    <ul className="list-group list-group-flush rounded">
      {[...Array(8)].map((el, i) => (
        <li className="list-group-item border my-2 rounded p-md-6" key={i}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="col">
              <div className="row">
                <div className="col-3">
                  <div style={shimmerStyle}></div>
                  <div style={shimmerStyle}></div>
                </div>
                <div className="col-4">
                  <div style={shimmerStyle}></div>
                  <div style={shimmerStyle}></div>
                </div>
                <div className="col-2">
                  <div style={shimmerStyle}></div>
                  <div style={shimmerStyle}></div>
                </div>
                <div className="col d-flex justify-content-center align-items-center">
                  <div style={shimmerStyle}></div>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
