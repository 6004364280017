import { useEffect, useState } from "react";
import CartItem from "./item";
import CartSideBar from "./sidebar";
import { DISABLED_COLOR, PRIMARY_COLOR } from "../../utils/constants";
import EmptyCart from "./empty";
import CartEmptyModal from "./cartEmptyModal";
import ChangeConfigModal from "./changeConfigModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { api, getBasketList } from "../../api";
import { toast } from "react-toastify";
import CartShimmerScreen from "./shimmer";

export default function CartScreen() {
  const [cart, setCart] = useState();
  const [checkedAll, setCheckedAll] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [deletingCart, setDeletingCart] = useState(false);

  const { data, isPending, mutate } = useMutation({
    mutationFn: () => getBasketList(),
    queryKey: ["getBasketList"],
    onSuccess: (data) => {
      setCart(data);
      const temp = cart.filter((l) => l.isChecked == true) || [];
      setCheckedAll(temp?.length == cart?.length);
    },
  });
  useEffect(() => {
    mutate();
  }, []);

  const calculateTotalPrice = () => {
    let tempTotalPrice = 0;
    let tempDeliveryPrice = 0;

    for (let i = 0; i < cart?.length; i++) {
      const element = cart[i];
      if (element.isChecked) {
        const totalPrice =
          element?.FullPrice?.ConvertedPriceList?.Internal?.Price || [];
        const deliveryPrice =
          element?.DeliveryPrice?.ConvertedPriceList?.Internal?.Price || [];
        const qty = element?.Quantity;
        const tpice = parseInt(totalPrice) * parseInt(qty);
        tempTotalPrice += tpice;
        tempDeliveryPrice += deliveryPrice;
      }
    }
    let tempTotalAmount =
      parseInt(tempTotalPrice) + parseInt(tempDeliveryPrice);
    setTotalPrice(tempTotalPrice);
    setDeliveryPrice(tempDeliveryPrice);
    setTotalAmount(tempTotalAmount);
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [cart]);

  const changeItemQuantity = async (id, increase) => {
    const temp = [...cart];
    const index = temp.findIndex((item) => item.Id == id);
    temp[index].isChangingQty = true;
    setCart(temp);
    let qty = parseInt(temp[index].Quantity);
    qty = increase ? qty + 1 : qty - 1;
    const { data } = await api.post("/editBasketItemQty", {
      elementId: id,
      quantity: qty,
    });
    if (data?.data?.ErrorCode == "Ok") {
      const newArr = [...cart];
      const index = temp.findIndex((item) => item.Id == id);
      newArr[index].Quantity = qty;
      newArr[index].isChangingQty = false;
      setCart(newArr);
    } else {
      window.location.reload();
      toast.error("Алдаа гарлаа");
    }
  };

  const checkItem = (id) => {
    const temp = [...cart];
    const index = temp.findIndex((item) => item.Id == id);
    const isChecked = temp[index].isChecked || false;
    temp[index].isChecked = !isChecked;
    setCart(temp);
    const checkedItems = cart.filter((l) => l.isChecked == true) || [];
    setCheckedAll(checkedItems.length == cart.length);
  };
  const checkAll = () => {
    const temp = [...cart];
    temp.map(function (x) {
      x.isChecked = !checkedAll;
      return x;
    });
    setCart(temp);
    setCheckedAll(!checkedAll);
  };
  const emptyCart = async () => {
    setDeletingCart(true);
    const { data } = await api.post("/clearBasket");
    if (data?.data?.ErrorCode == "Ok") {
      const temp = [];
      setCart(temp);
      setDeletingCart(false);
      toast.success("Амжилттай устгалаа");
    } else {
      window.location.reload();
      toast.error("Алдаа гарлаа");
    }
  };
  const deleteItem = async (id) => {
    const temp = [...cart];
    const index = temp.findIndex((item) => item.Id == id);
    temp[index].isDeleting = true;
    setCart(temp);
    const { data } = await api.post("/removeItemFromBasket", {
      elementId: id,
    });
    if (data?.data?.ErrorCode == "Ok") {
      const newArr = temp.filter((item) => item.Id !== id);
      setCart(newArr);
      toast.success("Амжилттай устгалаа");
    } else {
      temp[index].isDeleting = false;
      setCart(temp);
      toast.error("Алдаа гарлаа");
    }
  };

  if (isPending) {
    return <CartShimmerScreen />;
  }

  if (cart?.length == 0) {
    return <EmptyCart />;
  } else {
    return (
      <section className="my-xxl-8">
        <div className="container">
          <h4 className="mb-6 d-none d-md-block">Миний сагс</h4>
          <div className="row">
            <div className="col-lg-8 col-md-7">
              <div className="border-0 border-md p-md-border rounded p-0 p-md-6">
                <ul className="list-group list-group-flush rounded">
                  <div className="list-group-item border-bottom-0">
                    <div
                      className="row"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div className="col-1">
                        <div className="d-flex justify-content-end justify-content-md-center align-items-center">
                          <i
                            onClick={checkAll}
                            className={`bi bi-check-circle-fill fs-4`}
                            style={{
                              color: checkedAll
                                ? PRIMARY_COLOR
                                : DISABLED_COLOR,
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="col-5"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h5>Taobao ({cart?.length})</h5>
                      </div>
                      <div
                        className="col p-0"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div
                          className="btn rounded border"
                          data-bs-toggle={"modal"}
                          data-bs-target={"#cartEmptyModal"}
                          data-bs-dismiss={"offcanvas"}
                        >
                          <i className="bi bi-trash me-3"></i>
                          Сагс хоослох
                        </div>
                      </div>
                    </div>
                  </div>
                  {cart?.map((el, i) => (
                    <li
                      className="list-group-item py-3 border-bottom-0"
                      key={i}
                    >
                      <CartItem
                        element={el}
                        checkItem={checkItem}
                        deleteItem={deleteItem}
                        changeItemQuantity={changeItemQuantity}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* sidebar */}
            <div className="col-12 col-lg-4 col-md-5 mt-6 mt-md-0">
              <CartSideBar
                totalAmount={totalAmount}
                totalPrice={totalPrice}
                deliveryPrice={deliveryPrice}
                cart={cart}
              />
            </div>
          </div>
        </div>
        <CartEmptyModal emptyCart={emptyCart} loading={deletingCart} />
      </section>
    );
  }
}
