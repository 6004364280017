import { useNavigate } from "react-router-dom";

export default function EmptyCart() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100%",
        height: "30rem",
      }}
    >
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <i className="bi bi-cart2" style={{ fontSize: 74 }}></i>
        <h4>Таны сагс хоосон байна</h4>
        <button className="btn border mt-8" onClick={() => navigate("/")}>
          Нүүр хуудасруу буцах
        </button>
      </div>
    </div>
  );
}
