import { useState } from "react";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { recoverPassword } from "../../api";

export default function PasswordRecoveryModal() {
  const [loginInfo, setLoginInfo] = useState("");
  const [response, setResponse] = useState({
    received: false,
    success: false,
    message: "",
  });

  const { mutate: submit, isPending } = useMutation({
    mutationFn: () => recoverPassword(loginInfo),
    mutationKey: ["recoverPassword"],
    onSuccess: (data) => {
      const { ConfirmationType, ErrorDescription } = data;
      if (ConfirmationType) {
        setResponse((prevState) => ({
          ...prevState,
          success: true,
          received: true,
        }));
      } else {
        setResponse((prevState) => ({
          ...prevState,
          received: true,
          success: false,
          message: ErrorDescription,
        }));
      }
    },
    onError: (err) => {
      toast.error(err || "Алдаа гарлаа");
    },
  });

  return (
    <>
      <div
        className="modal fade"
        id="passRecoveryModal"
        tabIndex={-1}
        aria-labelledby="passRecoveryModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-4">
            <div className="modal-header border-0">
              <h5
                className="modal-title fs-3 fw-bold"
                id="passRecoveryModalLabel"
              >
                Нууц үг сэргээх
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="mb-8"
              >
                <img src="/assets/images/svg/logo.svg" />
              </div>

              <form>
                <div className="mb-6">
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    placeholder="Имэйл юмуу нууц үгээ оруулна уу"
                    required
                    onChange={(e) => setLoginInfo(e.target.value)}
                  />
                </div>
                {response.received && response.success && (
                  <div
                    className="alert alert-success mt-0 mb-6 border-0"
                    role="alert"
                  >
                    Шинэчлэх линкийг таны И-мэйл хаяг руу илгээлээ
                  </div>
                )}
                {response.received && !response.success && (
                  <div
                    className="alert alert-danger mt-0 mb-6 border-0"
                    role="alert"
                  >
                    {response.message}
                  </div>
                )}

                {!isPending ? (
                  <button
                    type="submit"
                    className="btn btn-primary loading"
                    style={{ width: "100%" }}
                    onClick={(e) => {
                      e.preventDefault();
                      submit();
                    }}
                  >
                    {!response.received ? "Сэргээх" : "Дахин илгээх"}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="button"
                    disabled
                    style={{ width: "100%" }}
                  >
                    <span
                      className="spinner-border spinner-border-sm me-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Түр хүлээнэ үү...
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
