import LogoutModal from "../../components/modals/logout";
import EditScreen from "./edit";
import InfoScreen from "./info";
import OrdersScreen from "./orders";
import { useLocation, useParams } from "react-router-dom";
import UserAddressScreen from "./address/address";
import WalletScreen from "./wallet";

export default function ProfileScreen() {
  const location = useLocation();
  let { screen } = useParams();
  const getClassName = (name) => {
    let className = "nav-link";
    if (screen == name) {
      className += " active";
    }
    return className;
  };

  return (
    <main>
      {/* section */}
      <section className="my-6">
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div className="col-lg-3 col-md-4 col-12">
              <div className="pt-8 pt-md-10 pe-lg-10">
                {/* nav */}
                <ul className="nav flex-column nav-pills nav-pills-dark">
                  {/* nav item */}
                  <li className="nav-item">
                    <a
                      className={getClassName("info")}
                      aria-current="page"
                      href="/profile/info"
                    >
                      <i className="feather-icon icon-user me-2" />
                      Профайл
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className={getClassName("edit")} href="/profile/edit">
                      <i className="feather-icon icon-edit me-2" />
                      Мэдээлэл өөрчлөх
                    </a>
                  </li>
                  {/* nav item */}
                  <li className="nav-item">
                    <a
                      className={getClassName("orders")}
                      aria-current="page"
                      href="/profile/orders"
                    >
                      <i className="feather-icon icon-shopping-bag me-2" />
                      Захиалгууд
                    </a>
                  </li>
                  {/* nav item */}
                  <li className="nav-item">
                    <a
                      className={getClassName("address")}
                      href="/profile/address"
                    >
                      <i className="feather-icon icon-map-pin me-2" />
                      Хүргэлтийн хаягууд
                    </a>
                  </li>
                  {/* nav item */}
                  <li className="nav-item">
                    <a className={getClassName("purse")} href="/profile/purse">
                      <i className="feather-icon icon-credit-card me-2" />
                      Хэтэвч
                    </a>
                  </li>
                  {/* nav item */}
                  <li className="nav-item d-none d-md-block">
                    <hr />
                  </li>
                  {/* nav item */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="../index.html"
                      data-bs-toggle={"modal"}
                      data-bs-target={"#logoutModal"}
                      onClick={() => {}}
                    >
                      <i className="feather-icon icon-log-out me-2" />
                      Гарах
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <li className="nav-item d-block d-md-none">
              <hr />
            </li>
            <div className="col-lg-9 col-md-8 col-12">
              {screen == "info" ? (
                <InfoScreen />
              ) : screen == "edit" ? (
                <EditScreen />
              ) : screen == "orders" ? (
                <OrdersScreen />
              ) : screen == "address" ? (
                <UserAddressScreen />
              ) : screen == "purse" ? (
                <WalletScreen />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
      <LogoutModal />
    </main>
  );
}
