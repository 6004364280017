import { Tooltip } from "react-tooltip";
import { SECONDARY_COLOR } from "../../utils/constants";

export default function ProductConfigurator({ data, changeProperty, obj }) {
  const isActive = (e) => {
    const findIndex = obj.findIndex((a) => a.value === e.Id);
    return findIndex !== -1;
  };
  return (
    <div className="my-6">
      {data?.Configurators?.Property?.map((el, index) => (
        <div className="mb-3">
          <h5 className="mb-3">{el.DisplayName}</h5>
          <div className="d-flex flex-wrap">
            {el.Value.map((e, i) => (
              <>
                {e.Picture ? (
                  <div
                    className="m-1"
                    onClick={() => changeProperty(el, e)}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={e.DisplayName}
                  >
                    <img
                      src={e.Picture.Url}
                      width={"50rem"}
                      height={"50rem"}
                      style={{ objectFit: "cover", cursor: "pointer" }}
                      className={
                        isActive(e)
                          ? `border rounded border-3 border-danger`
                          : `border rounded`
                      }
                    ></img>
                  </div>
                ) : (
                  <button
                    onClick={() => changeProperty(el, e)}
                    type="button"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={e.DisplayName}
                    className={
                      isActive(e)
                        ? "btn btn-sm border rounded m-1 border-3 border-danger"
                        : "btn btn-sm border rounded m-1"
                    }
                  >
                    {e.DisplayName}
                  </button>
                )}
              </>
            ))}
          </div>
        </div>
      ))}
      <Tooltip
        id="my-tooltip"
        style={{ backgroundColor: SECONDARY_COLOR, color: "white" }}
      />
    </div>
  );
}
