import { SHIMMER_COLOR } from "../../../utils/constants";

export default function UserProfileAddressShimmer() {
  const shimmerStyle = {
    width: "100%",
    height: 12,
    borderRadius: 25,
    backgroundColor: SHIMMER_COLOR,
    margin: "8px",
  };
  return (
    <ul className="list-group list-group-flush rounded">
      {[...Array(3)].map((el, i) => (
        <li
          style={{ cursor: "pointer" }}
          className="list-group-item border my-2 rounded p-md-6"
          key={i}
        >
          <div className="col">
            <div className="row">
              <div className="col">
                <div style={shimmerStyle}></div>
                <div style={shimmerStyle}></div>
              </div>
              <div className="col">
                <div style={shimmerStyle}></div>
                <div style={shimmerStyle}></div>
              </div>
              <div className="col">
                <div style={shimmerStyle}></div>
                <div style={shimmerStyle}></div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div style={shimmerStyle}></div>
                <div style={shimmerStyle}></div>
              </div>
              <div className="col">
                <div style={shimmerStyle}></div>
                <div style={shimmerStyle}></div>
              </div>
              <div className="col">
                <div style={shimmerStyle}></div>
                <div style={shimmerStyle}></div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
