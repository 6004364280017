import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

export default function BrandHeader() {
  const { id } = useParams();
  const { data, loading, error } = useFetch(`/getVendorInfo?vendorId=${id}`);

  if (loading) {
    return (
      <div
        className="mb-3"
        style={{
          backgroundColor: "#ecf0ef",
          height: "5rem",
          width: "100%",
          borderRadius: 4,
        }}
      ></div>
    );
  }

  return (
    <section className="mb-8">
      {/* contianer */}
      <div className="col-12">
        {/* cta */}
        <div className="bg-light d-flex justify-content-between align-items-center py-6 py-lg-3 px-8 text-center text-lg-start rounded">
          {/* img */}
          <div className="d-flex align-items-center">
            <img
              src={data?.VendorInfo?.PictureUrl}
              alt
              className="img-fluid"
              style={{ maxWidth: "5rem" }}
            />
            <div className="ms-4">
              <div className="">
                <h4 className="fs-2 mb-1">{data?.VendorInfo?.DisplayName}</h4>
                <div className="">
                  <span>{data?.VendorInfo?.ShopName}</span>
                </div>
                <div>
                  <small className="text-warning">
                    <i className="bi bi-star-fill" />
                    <i className="bi bi-star-fill" />
                    <i className="bi bi-star-fill" />
                    <i className="bi bi-star-fill" />
                    <i className="bi bi-star-half" />
                  </small>
                  <span className="ms-2">
                    {data?.VendorInfo?.Scores.ItemScore}
                  </span>
                  <a href="#" className="ms-2">
                    {data?.VendorInfo?.FeaturedValues.map((e, i) =>
                      e.Name === "Fans" ? "30 reviews" : ""
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
