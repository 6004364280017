export default function ZaavarScreen() {
  return (
    <div className="container">
      <div className="row">
        <div className="col col-md-8 m-auto">
          <h4 className="my-6 mt-8 text-center">Бүртгүүлэх заавар</h4>
          <div className="mb-6" style={{ textAlign: "justify" }}>
            QMALL.mn сайтаас бараа захиалахын тулд та заавал эхлээд бүртгүүлсэн
            байх шаардлагатай.{" "}
          </div>
          <img
            src="assets/images/jpg/zaavar1.jpg"
            style={{ width: "100%" }}
            className="mb-6"
          />
          <div className="mb-6" style={{ textAlign: "justify" }}>
            Бүртгүүлэхийн тулд сайтын баруун дээд буланд байрлах БҮРТГҮҮЛЭХ товч
            дээр дарж бүртгүүлэх хэсэг рүү орно. Эсвал та Google, Twitter болон
            Facebook-н эрхээр нэвтрэх товч дээр дарж сайтад хялбар бүртгүүлж
            болно.
          </div>
          <img
            src="assets/images/jpg/zaavar2.jpg"
            style={{ width: "100%" }}
            className="mb-6"
          />
          <div className="mb-6" style={{ textAlign: "justify" }}>
            Бүртгүүлэх товчийг дарахад дараах цонх гарч ирэх бөгөөд та өөрийн
            И-мэйл хаяг, Нэр мөн "Нууц үг" хэсэгт сайтад нэвтрэн орох шинэ нууц
            үгээ оруулна. (Таны шинээр оруулж буй нууц үг 6 орноос дээш байх
            ёстойг анхаарна уу.) Өөрийн И-мэйл хаяг болон шинэ нууц үгээ
            оруулсан бол баталгаажуулах хэсгийг баталгаажуулж /reCaptcha/
            "Бүртгүүлэх" товчийг даран сайтад амжилттай бүртгүүлж дуусна.
          </div>
          <img
            src="assets/images/jpg/zaavar3.jpg"
            style={{ width: "100%" }}
            className="mb-6"
          />
          <div className="mb-14" style={{ textAlign: "justify" }}>
            Та аль хэдийн бүртгүүлсэн бол НЭВТРЭХ товч дээр даран сайтруу
            нэвтрэрч болно. Мөн та өөрийн Facebook болон Google, Twittter
            эрхүүдийг ашиглан QMALL.mn сайтад нэвтрэн орох боломжтой. (Эдгээр
            эрхүүдийн бүртгэлдээ заавал өөрийн и-мэйл хаягаа оруулж өгсөн байх
            шаардлагатай.) Та сайтад нэвтрэн орсоноор захиалга хийх боломжтой
            болно.
          </div>
        </div>
      </div>
    </div>
  );
}
