import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { changeCatName } from "../../utils/helpers";
import { getCategoryList } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { SHIMMER_COLOR } from "../../utils/constants";

export default function CategoryScreenDropDown({ isLoading, products }) {
  let { cat1 } = useParams();
  const { data, isPending } = useQuery({
    queryFn: () => getCategoryList(),
    queryKey: ["getCategoryList"],
  });

  const cat = data?.find((e) => e.Id == `otc-${cat1}`) || [];
  const navigate = useNavigate();
  const onChange = (e) => {};

  return (
    <div
      className={`offcanvas offcanvas-start offcanvas-collapse w-md-50 ${
        products?.length == 0 && isLoading && "mt-6"
      }`}
      tabIndex={-1}
      id="offcanvasCategory"
      aria-labelledby="offcanvasCategoryLabel"
    >
      <div className="offcanvas-body ps-lg-2 pt-lg-0">
        <div className="mb-8">
          {/* nav */}
          <ul className="nav nav-category" id="categoryCollapseMenu">
            {products?.length == 0 && isLoading
              ? [...Array(24)].map((el, i) => (
                  <li className="nav-item border-bottom w-100">
                    <div
                      key={i}
                      style={{
                        width: "100%",
                        height: 12,
                        backgroundColor: SHIMMER_COLOR,
                        margin: "12px",
                        borderRadius: 25,
                      }}
                    />
                  </li>
                ))
              : cat?.Children?.map((e, i) => (
                  <li className="nav-item border-bottom w-100" key={i}>
                    <a
                      href="#"
                      className="nav-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target={`#categoryFlush${i}`}
                      aria-expanded="false"
                      aria-controls={`categoryFlush${i}`}
                    >
                      {e.Name}
                      <i className="feather-icon icon-chevron-right" />
                    </a>
                    {/* accordion collapse */}
                    <div
                      id={`categoryFlush${i}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#categoryCollapseMenu"
                    >
                      <div>
                        {/* nav */}
                        <ul className="nav flex-column ms-3">
                          {e?.Children?.map((k, n) => (
                            <li
                              className="nav-item active"
                              key={n}
                              onClick={() =>
                                navigate(
                                  `/category/${cat1}/${changeCatName(
                                    e.Id
                                  )}/${changeCatName(k.Id)}`,
                                  { replace: true }
                                )
                              }
                            >
                              <div
                                className="nav-link"
                                style={{ cursor: "pointer" }}
                              >
                                {k.Name}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
