export default function CartEmptyModal({ emptyCart, loading }) {
  return (
    <>
      <div
        className="modal fade"
        id="cartEmptyModal"
        tabIndex={-1}
        aria-labelledby="cartEmptyModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-4">
            <div className="modal-header border-0">
              <h5 className="modal-title fs-3 fw-bold" id="cartEmptyModalLabel">
                Анхааруулга
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <span>Та сагсаа хоослохдоо итгэлтэй байна уу?</span>
            </div>
            <div className="mt-8 d-flex justify-content-end">
              <button
                className="btn border"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Болих
              </button>

              {loading ? (
                <button className="btn btn-primary ms-3" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm me-3"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Түр хүлээнэ үү...
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary ms-3"
                  onClick={emptyCart}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Хоослох
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
