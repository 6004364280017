import { useSearchParams } from "react-router-dom";
import { filters } from "../../mock/filters";
import useMediaQuery from "../../hooks/useMediaQuery";

export default function ProductFilter({ totalCount }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort") || "";
  const isMobile = useMediaQuery("(max-width: 768px)");

  const onChange = (e) => {
    if (e.target.value === "") {
      searchParams.delete("sort");
    } else {
      searchParams.set("sort", e.target.value);
    }
    setSearchParams(searchParams);
    window.scrollTo(0, 0);
  };
  return (
    <div
      className="d-lg-flex justify-content-between align-items-center mb-3 mb-lg-0"
      style={{ display: "flex", alignItems: "center" }}
    >
      <p className="mb-3">
        <span className="text-secondary">Нийт: {totalCount} бараа</span>
      </p>
      <div style={{ maxWidth: isMobile && "10rem" }}>
        <select className="form-select" onChange={onChange} value={sort}>
          {filters.map((e, i) => (
            <option value={e.value} key={i}>
              {e.title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
