import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ProductImageSlide from "./image-slide";
import ProductMainInformation from "./main-info";
import ProductDetailedInfo from "./detailed-info";
import ProductDetailsShimmer from "./shimmer";
import { useMutation, useQuery } from "@tanstack/react-query";
import ProductPrice from "./price";
import ProductConfigurator from "./configurator";
import ProductController from "./controller";
import ProductVendor from "./vendor";
import { capitalizeFirstLetter, formatCurrency } from "../../utils/helpers";
import {
  addItemToBasket,
  fetchConfiguration,
  fetchDescriptions,
  fetchMainInfo,
} from "../../api";
import { toast } from "react-toastify";
import { SHIMMER_COLOR } from "../../utils/constants";

export default function ProductDetailsScreen() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const c = searchParams.get("c") || 0;
  const [qty, setQty] = useState(1);
  const [obj, setObj] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    isLoading: loadingInfo,
    isError: infoError,
    data: mainInfo,
  } = useQuery({
    queryFn: () => fetchMainInfo(id),
    queryKey: ["productMainInfo", id],
  });

  const {
    isLoading: loadingConfig,
    isError: configError,
    data: config,
  } = useQuery({
    queryFn: () => fetchConfiguration(id, {}),
    queryKey: ["productConfiguration", id],
    enabled: !loadingInfo,
  });
  const {
    isLoading: loadingDesc,
    isError: descError,
    data: description,
  } = useQuery({
    queryFn: () => fetchDescriptions(id),
    queryKey: ["productDescription", id],
    enabled: !loadingConfig,
  });

  const {
    data: configData,
    isPending: fetchingConfig,
    mutate: fetchConfigs,
  } = useMutation({
    mutationFn: (data) => fetchConfiguration(id, data),
    mutationKey: ["productConfiguration", id],
    onSuccess: (data) => {
      if (data?.Configuration?.Current?.ConfigurationId) {
        navigate(
          `/product/${id}?c=${data?.Configuration?.Current?.ConfigurationId}`,
          { replace: true }
        );
        return;
      }
    },
  });

  const { isPending, mutate: addToCart } = useMutation({
    mutationFn: () => addItemToBasket(id, c, qty),
    mutationKey: ["addItemToBasket", id],
    onSuccess: (data) => {
      toast.success("Сагсанд нэмэгдлээ");
    },
    onError: (err) => {
      toast.error(err || "Алдаа гарлаа");
    },
  });

  const changeProperty = (el, e) => {
    setObj((o) => {
      const index = o.findIndex((a) => a.id === el.Id);
      index !== -1 && o.splice(index, 1);
      o.push({
        type: "property",
        id: el.Id,
        value: e.Id,
      });
      return o;
    });
    fetchConfigs(obj);
  };

  const changeQty = (increase) => {
    const temp = increase ? qty + 1 : qty - 1;
    setObj((o) => {
      const index = o.findIndex((a) => a.type === "qty");
      index !== -1 && o.splice(index, 1);
      o.push({
        type: "qty",
        qty: temp,
      });
      return o;
    });
    setQty(temp);
    fetchConfigs(obj);
  };

  if (loadingInfo) {
    return <ProductDetailsShimmer />;
  }
  return (
    <div>
      <section className="mt-8">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-xl-6">
              <ProductImageSlide data={mainInfo?.Item} />
            </div>
            <div className="col-md-7 col-xl-6">
              <div className="ps-lg-10 mt-6 mt-md-0">
                <h3 className="mb-1">
                  {capitalizeFirstLetter(mainInfo?.Item.Title)}
                </h3>
                <ProductPrice isLoading={loadingConfig} data={config} />
                <ProductConfigurator
                  data={mainInfo?.Item}
                  changeProperty={changeProperty}
                  obj={obj}
                />
                {fetchingConfig ? (
                  <div
                    style={{
                      backgroundColor: SHIMMER_COLOR,
                      height: 32,
                      width: "10rem",
                      borderRadius: 8,
                    }}
                  ></div>
                ) : (
                  configData?.Configuration?.TotalCost && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="me-3">Нийт үнэ:</span>
                      <span className="fw-bold text-dark fs-4">
                        {formatCurrency(configData?.Configuration?.TotalCost)}
                      </span>
                    </div>
                  )
                )}
                <ProductController
                  qty={qty}
                  changeQty={changeQty}
                  configurationId={c}
                  addToCart={addToCart}
                  addingToCart={isPending}
                  data={mainInfo?.Item}
                  totalPrice={configData?.Configuration?.TotalCost}
                />
                <hr className="my-6" />
                <ProductVendor vendor={mainInfo?.Vendor} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-lg-14 mt-8">
        <div className="container">
          <div className="row">
            <ProductDetailedInfo
              data={mainInfo?.Item}
              products={mainInfo?.VendorItems.Content}
              loading={loadingDesc}
              error={configError}
            />
          </div>
        </div>
      </section>
    </div>
  );
}
