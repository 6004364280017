import { useEffect, useState } from "react";
import CartSideBar from "./sidebar";
import { DISABLED_COLOR, PRIMARY_COLOR } from "../../utils/constants";
import { useQuery } from "@tanstack/react-query";
import { getDeliveryModes, getUserProfileInfo } from "../../api";
import CheckOutShimmerScreen from "./shimmer";
import { useLocation } from "react-router-dom";

export default function CheckOutNewScreen() {
  const { state } = useLocation();
  const warnings = [
    "Нялх хүүхэдтэй",
    "Хүргэхийн өмнө заавал залгах",
    "Зөвхөн оройн цагаар хүргэх",
  ];
  const [index, setIndex] = useState(0);
  const [commentList, setCommentList] = useState([]);
  const [text, setText] = useState("");
  const [profile, setProfile] = useState({});
  const [profileList, setProfileList] = useState([]);

  const { isLoading, isError, data } = useQuery({
    queryFn: () => getUserProfileInfo(),
    queryKey: ["getUserProfileInfo"],
  });

  const {
    data: mode,
    isLoading: modeLoading,
    isError: modeError,
  } = useQuery({
    queryFn: () => getDeliveryModes(),
    queryKey: ["getDeliveryModes"],
  });

  const selectWarning = (e) => {
    const index = commentList.indexOf(e);
    let newComment = [...commentList];
    if (index > -1) {
      newComment.splice(index, 1);
    } else {
      newComment.push(e);
    }
    setCommentList(newComment);
  };

  useEffect(() => {
    if (!isLoading) {
      const newProfileList = [
        ...data,
        {
          FirstName: "",
          LastName: "",
          Region: "",
          City: "",
          Address: "",
          Phone: "",
          ExternalDeliveryId: "",
          Id: "",
        },
      ];
      profileList.length < 3 && setProfileList(newProfileList);
      setProfile(newProfileList[0]);
    }
  }, [isLoading]);

  if (isLoading) {
    return <CheckOutShimmerScreen />;
  }

  return (
    <section className="my-xxl-8 my-6">
      <div className="container">
        <h4 className="mb-6">Хүргэлтийн мэдээлэл</h4>
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="border rounded p-6">
              <div>
                <div className="row mb-6">
                  <div className="d-flex justify-content-evenly">
                    {profileList?.map((e, i) => (
                      <div
                        key={i}
                        className="d-flex align-items-center"
                        onClick={() => {
                          setIndex(i);
                          setProfile(e);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className={`bi bi-check-circle-fill fs-4 me-3`}
                          style={{
                            color: index === i ? PRIMARY_COLOR : DISABLED_COLOR,
                          }}
                        />
                        {e.Id === "" ? "Шинэ хаяг" : `Хаяг - ${i + 1}`}
                      </div>
                    ))}
                  </div>
                </div>
                {mode?.map((e, i) => (
                  <div
                    className="d-flex justify-content-between align-items-center mb-6"
                    key={i}
                  >
                    <div>
                      <div className="mb-1 text-black">{e.Name}</div>
                      <p className="mb-0 text-secondary">
                        <small>{e.Description}</small>
                      </p>
                    </div>
                    {/* checkbox */}
                    <div className="form-check form-switch">
                      <input
                        disabled={profileList[index]?.Id !== ""}
                        checked={profile?.ExternalDeliveryId === e.Id}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        onChange={() =>
                          setProfile((prevState) => ({
                            ...prevState,
                            ExternalDeliveryId: e.Id,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>
                ))}
                {profileList[index]?.Id !== "" ? (
                  <div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="lname"
                        >
                          Овог
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          value={profileList[index]?.LastName}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="fname"
                        >
                          Нэр
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          value={profileList[index]?.FirstName}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="city"
                        >
                          Хот/Аймаг
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={profileList[index]?.City}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="region"
                        >
                          Хороо/Баг
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={profileList[index]?.Region}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="address"
                        >
                          Хаяг
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={profileList[index]?.Address}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="phone"
                        >
                          Утасны дугаар
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          value={profileList[index]?.Phone}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="lname"
                        >
                          Овог
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={profile.LastName}
                          onChange={(e) => {
                            setProfile((prevState) => ({
                              ...prevState,
                              LastName: e.target.value,
                            }));
                          }}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="fname"
                        >
                          Нэр
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={profile.FirstName}
                          onChange={(e) =>
                            setProfile((prevState) => ({
                              ...prevState,
                              FirstName: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="city"
                        >
                          Хот/Аймаг
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={profile.City}
                          onChange={(e) =>
                            setProfile((prevState) => ({
                              ...prevState,
                              City: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="region"
                        >
                          Хороо/Баг
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={profile.Region}
                          onChange={(e) =>
                            setProfile((prevState) => ({
                              ...prevState,
                              Region: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="address"
                        >
                          Хаяг
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="address"
                          value={profile.Address}
                          onChange={(e) =>
                            setProfile((prevState) => ({
                              ...prevState,
                              Address: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label text-secondary"
                          htmlFor="phone"
                        >
                          Утасны дугаар
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          id="phone"
                          className="form-control"
                          value={profile.Phone}
                          onChange={(e) =>
                            setProfile((prevState) => ({
                              ...prevState,
                              Phone: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <h4 className="my-6">Нэмэлт анхааруулга</h4>
            <div className="border rounded p-6">
              <div className="row gx-6 gy-3 mb-xl-6">
                {warnings.map((e, i) => (
                  <div
                    key={i}
                    className="d-flex align-items-center"
                    onClick={() => selectWarning(e)}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`bi bi-check-circle-fill fs-4 me-3`}
                      style={{
                        color:
                          commentList.indexOf(e) > -1
                            ? PRIMARY_COLOR
                            : DISABLED_COLOR,
                      }}
                    />
                    {e}
                  </div>
                ))}
              </div>
              <div className="row g-6 mb-xl-3">
                <div className="col-12">
                  {/* input */}
                  <div className="mb-3 mb-lg-0">
                    <textarea
                      onChange={(e) => {
                        setText(e.target.value);
                        console.log(text);
                      }}
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="*Захиалгатай холбоотой нэмэлт мэдээлэл байвал бичиж үлдээнэ үү"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* sidebar */}
          <div className="col-12 col-lg-4 col-md-5 mt-6 mt-md-0">
            <CartSideBar
              userProfile={profile}
              comment={commentList}
              text={text}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
