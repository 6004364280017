import { useParams, useSearchParams } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useEffect, useState } from "react";
import CategoryScreenDropDown from "./dropdown";
import CategoryTabs from "./tabs";
import Products from "../../components/products";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCategoryProducts } from "../../api";
import { useLocation } from "react-router-dom";

export default function CategoryScreen() {
  const { cat3 } = useParams();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("p") || 1;
  const sort = searchParams.get("sort") || "";

  const { data, isPending } = useQuery({
    queryFn: () => getCategoryProducts(cat3, page, sort),
    queryKey: ["getCategoryProducts", cat3, page, sort],
  });

  return (
    <main>
      <div className="mt-8 mb-14">
        <div className="container">
          <div className="row gx-5">
            <aside className="col-lg-3 col-md-4 mb-6 mb-md-0 d-none d-md-block">
              <CategoryScreenDropDown
                isLoading={isPending}
                data={data?.Items?.Content}
              />
            </aside>
            <section className="col-lg-9 col-md-12">
              <div className="d-block d-md-none">
                <CategoryTabs />
              </div>
              <Products
                data={data?.Items?.Content}
                loading={isPending}
                showFilter={true}
                showPagination={true}
                cols={4}
                pageCount={data?.MaximumPageCount}
                totalCount={data?.Items?.TotalCount}
              />
            </section>
          </div>
        </div>
      </div>
    </main>
  );
}
