import React from "react";
import ProductShimmer from "./shimmer";
import ProductPagination from "./pagination";
import ProductCards from "./cards";
import ProductFilter from "./filter";

export default function Products({
  cols = 5,
  data = [],
  loading = true,
  totalCount = 0,
  pageCount = 0,
  showPagination = true,
  showFilter = true,
}) {
  // const isMobile = useMediaQuery("(max-width: 768px)");
  // const { cat3 } = useParams();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const sort = searchParams.get("sort") || "";
  // const { data, loading, error } = useFetch(
  //   `/getProducts?cat=${cat3}&page=${page}&sort=${sort}`
  // );
  // data.Items.TotalCount;
  // data?.Items?.Content;
  // data?.MaximumPageCount;

  if (loading) {
    return <ProductShimmer count={12} cols={cols} />;
  }
  return (
    <div>
      {showFilter && <ProductFilter totalCount={totalCount} />}
      <ProductCards data={data} cols={cols} />
      {showPagination && <ProductPagination pageCount={pageCount} />}
    </div>
  );
}
